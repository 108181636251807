import no from './no.json';
import nn from './nn.json';
import en from './en.json';

export const dictionaryList = { no, nn, en };

export const languageOptions = {
    no: 'Norsk',
    nn: 'Nynorsk',
    en: 'English'
};