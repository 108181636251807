import { AppBar, Box, Divider, IconButton, Link as LinkExt, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import { Text } from "./Tekst";
import { useEffect, useState } from "react";
import { AccountCircle } from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";

const jvlang = localStorage.getItem("jvlang") || "no";
const jvtheme = localStorage.getItem("jvtheme") || "light";

export default function ToppMeny() {
    const jvbruker = sessionStorage.getItem('jvbruker');
    const jvtoken = sessionStorage.getItem('jvtoken');
    const jvaktorid = sessionStorage.getItem('jvaktorid');

    useEffect(() => {
        if ((window.location.pathname !== "/logginn") && (window.location.pathname !== "/nykonto") && (window.location.pathname !== "/glemtpassord")) {
            if ((!jvbruker) || (!jvtoken)) {
                window.location.assign("/logginn");
            }
        }
        if (window.location.pathname !== "/profil") {
            if ((jvbruker) && (jvtoken) && ((jvaktorid === "undefined") || (jvaktorid === null))) {
                window.location.assign("/profil");
            }
        }
    });

    useEffect(() => {
        setInterval(() => {
            sessionStorage.clear();
            window.location.assign("/");
        }, 3600000);
    });

    const toggleDarkMode = () => {
        const jvtheme = localStorage.getItem("jvtheme") || "light";
        if (jvtheme === "dark") {
            localStorage.setItem("jvtheme", "light");
            window.location.reload();
        }
        else {
            localStorage.setItem("jvtheme", "dark");
            window.location.reload();
        }
    }

    const setLanguage = (lang: any) => {
        if (lang === "no") {
            localStorage.setItem("jvlang", "no");
            window.location.reload();
        }
        if (lang === "nn") {
            localStorage.setItem("jvlang", "nn");
            window.location.reload();
        }
        if (lang === "en") {
            localStorage.setItem("jvlang", "en");
            window.location.reload();
        }
    }


    const [panchorEl, setpAnchorEl] = useState<null | HTMLElement>(null);
    const handlepMenu = (event: React.MouseEvent<HTMLElement>) => {
        setpAnchorEl(event.currentTarget);
    };
    const handlepClose = () => {
        setpAnchorEl(null);
    };

    const [lanchorEl, setlAnchorEl] = useState<null | HTMLElement>(null);
    const handlelMenu = (event: React.MouseEvent<HTMLElement>) => {
        setlAnchorEl(event.currentTarget);
    };
    const handlelClose = () => {
        setlAnchorEl(null);
    };


    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="static">
                <Toolbar>
                    {jvtoken ?
                        <LinkExt href="/" className="jvhomeicon"><HomeIcon className="jvhomeicon" sx={{ mr: 2 }} /></LinkExt>
                        :
                        <LinkExt href="/logginn" className="jvhomeicon"><HomeIcon className="jvhomeicon" sx={{ mr: 2 }} /></LinkExt>
                    }
                    <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
                        <Text tid="dinsideHeader" />
                    </Typography>
                    {(jvtheme === 'light') ?
                        <img src='/mode-dark.png' onClick={toggleDarkMode} color="inherit" className="headermenuthemeicon" alt='Lys/Mørk modus' />
                        :
                        <img src='/mode-light.png' onClick={toggleDarkMode} color="inherit" className="headermenuthemeicon" alt='Lys/Mørk modus' />
                    }
                    <div>
                        <IconButton
                            size="large"
                            aria-label="språkvalg"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handlelMenu}
                            color="inherit"
                        >
                            {((jvlang === 'no') || (jvlang === 'nn')) ?
                                <img src='/flag-no.png' color="inherit" className="headerlangicon" alt='Språkvalg' />
                                :
                                <img src='/flag-en.png' color="inherit" className="headerlangicon" alt='Språkvalg' />
                            }
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={lanchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(lanchorEl)}
                            onClose={handlelClose}
                        >
                            <MenuItem
                                onClick={e => setLanguage("no")}
                            >
                                <img src='/flag-no.png' color="inherit" className="headermenulangicon" alt='Språkvalg' />  Norsk (Bokmål)
                            </MenuItem>
                            <MenuItem
                                onClick={e => setLanguage("nn")}
                            >
                                <img src='/flag-no.png' color="inherit" className="headermenulangicon" alt='Språkvalg' />  Norsk (Nynorsk)
                            </MenuItem>
                            <MenuItem
                                onClick={e => setLanguage("en")}
                            >
                                <img src='/flag-en.png' color="inherit" className="headermenulangicon" alt='Språkvalg' />  English
                            </MenuItem>
                        </Menu>
                    </div>


                    {jvbruker && (
                        <div>
                            <IconButton
                                size="large"
                                aria-label="brukerkonto"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handlepMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={panchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(panchorEl)}
                                onClose={handlepClose}
                            >
                                <MenuItem
                                    onClick={handlepClose}
                                    component={Link}
                                    to="/profil"
                                >
                                    <AccountCircle color="primary" className='headermenuicon' />  <Text tid="minprofil" />
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                    onClick={handlepClose}
                                    component={Link}
                                    to="/loggut"
                                >
                                    <LogoutIcon color="error" className='headermenuicon' /> <Text tid="loggut" />
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}