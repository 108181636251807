import { Backdrop, Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Tjenesteknapper from '../komponenter/Tjenesteknapper';


function Forsiden() {
    const [isLoading, setisLoading] = useState(true);
    const jvbruker = sessionStorage.getItem('jvbruker');
    const jvnavn = sessionStorage.getItem('jvnavn');
    const jvmobilnummer = sessionStorage.getItem('jvmobilnummer');
    const jvaktornavn = sessionStorage.getItem('jvaktornavn');
    const jvaktororgnr = sessionStorage.getItem('jvaktororgnummer');
    const token = sessionStorage.getItem("jvtoken") || "";

    useEffect(() => {
        document.title = 'Din Side - Justervesenet';
        if (token !== "") {
            setisLoading(false);
        }
        else {
            window.location.assign("/logginn");
        }
    }, [token]);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <ToppMeny />
                        <Grid container spacing={2} >
                            <Grid item xs={12} mt={2} p={2} ml={2} mr={2}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid container sx={{ justifyContent: 'space-between', alignItems: "center" }}>
                                            <Typography variant="h6" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                <AccountCircle className='jvtexticon' /> {jvnavn}
                                            </Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button
                                                    size="small"
                                                    href="/profil"
                                                    variant="outlined"
                                                    startIcon={<SettingsIcon />}
                                                    sx={{ mr: 1 }}
                                                >
                                                    <Text tid="minprofil" />
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3 }}>
                                            <EmailIcon className='jvtexticonnc' /> {jvbruker}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3, mb: 3 }}>
                                            <PhoneIcon className='jvtexticonnc' /> {jvmobilnummer}
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary" gutterBottom className='jvtypeicon'>
                                            <BusinessIcon className='jvtexticon' /> {jvaktornavn}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3 }}>
                                            <ListAltIcon className='jvtexticonnc' /> {jvaktororgnr}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" m={2}>
                                    <Typography variant="body1" color="text.secondary">
                                    </Typography>
                                    <Grid container spacing={2} mt={5}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" color="text.secondary" component="h2" sx={{ mb: 3 }}>
                                                <Text tid="tjenester" />
                                            </Typography>
                                            <Box display="flex" width="100%" maxWidth="900px">
                                                <Box m={2}>
                                                    <Tjenesteknapper link="/taksameter" Icon="LocalTaxiIcon" Tittel="taksameter" Info="servicetaksameterinfo" />
                                                </Box>
                                                {/* <Box m={2}>
                                                    <Tjenesteknapper link="/aarsavgift" Icon="PointOfSaleIcon" Tittel="aarsavgift" Info="aarsavgiftinfo" />
                                                </Box> */}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
            }
        </main>
    );
}

export default Forsiden;