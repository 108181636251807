import { AppBar, Autocomplete, Backdrop, Box, Breadcrumbs, Button, ButtonGroup, Card, CardContent, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Link, MenuItem, Radio, RadioGroup, Slide, Snackbar, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState, useRef, forwardRef } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { LocalTaxiOutlined, TaxiAlertOutlined } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BadgeIcon from '@mui/icons-material/Badge';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LinkIcon from '@mui/icons-material/Link';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import VerifiedIcon from '@mui/icons-material/Verified';
import moment from 'moment';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import ConstructionIcon from '@mui/icons-material/Construction';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import { TransitionProps } from '@mui/material/transitions';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const APIURL = process.env.REACT_APP_APIURL;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function TaksameterDetaljer() {
    const { orgnr } = useParams();
    const [valueTab, setValueTab] = React.useState(0);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");
    const [isLoading, setisLoading] = useState(true);
    const [isLoadingLokasjonOptions, setisLoadingLokasjonOptions] = useState(true);
    const [ModalUtmontering, setModalUtmontering] = useState(false);
    const [ModalHistorikk, setModalHistorikk] = useState(false);
    const [ModalPlombeBrudd, setModalPlombebrudd] = useState(false);
    const [ModalEndreLokasjon, setModalEndreLokasjon] = useState(false);
    const [VisObservasjoner, setVisObservasjoner] = useState(true);
    const [VisPlomber, setVisPlomber] = useState(true);
    const [AktivAktorId, setAktivAktorId] = useState(0);
    const [AktivKommuneAktorNavn, setAktivKommuneAktorNavn] = useState("");
    const [AktivtDrosjeomraade, setAktivtDrosjeomraade] = useState("");
    const [AktivtDrosjesentralAktor, setAktivtDrosjesentralAktor] = useState("");
    const [AktivtDrosjesentralOrgNummer, setAktivtDrosjesentralOrgNummer] = useState("");
    const [AktivtBilmerke, setAktivtBilmerke] = useState("");
    const [AktivtAarsmodell, setAktivtAarsmodell] = useState("");
    const [AktivtRegnr, setAktivtRegnr] = useState("");
    const [AktivtDekkdim, setAktivtDekkdim] = useState("");
    const [AktivtFabrikat, setAktivtFabrikat] = useState("");
    const [AktivtModell, setAktivtModell] = useState("");
    const [AktivtSerienummer, setAktivtSerienummer] = useState("");
    const [AktivtSoftwareversjon, setAktivtSoftwareversjon] = useState("");
    const [AktivtKFaktor, setAktivtKFaktor] = useState("");
    const [AktivtStrekkode, setAktivtStrekkode] = useState("");
    const [AktivtInstrument, setAktivtInstrument] = useState(0);
    const [NyttOmraadeId, setNyttOmraadeId] = useState(0);
    const [aktor, setAktor] = useState<any[]>([]);
    const [kommuner, setKommuner] = useState<any[]>([]);
    const token = sessionStorage.getItem("jvtoken") || "";
    const [NyePlomber, setNyePlomber] = useState<any[]>([]);
    const [BrregRespons, setBrregRespons] = useState<any[]>([]);
    const [ModalAktivePlomber, setModalAktivePlomber] = useState<any[]>([]);
    const [AktivtInstrumentIndex, setAktivtInstrumentIndex] = useState(0);
    const [rettefristAarsak, setRettefristAarsak] = useState(0);
    const [AktivRettefristSaksnummer, setAktivRettefristSaksnummer] = useState("");
    const [AktivRettefristDato, setAktivRettefristDato] = useState("");
    const [AktivRettefrist, setAktivRettefrist] = useState(0);
    const [plombeBruddAarsakValg, setPlombeBruddAarsakValg] = useState(1);
    const [ModalEndreAktor, setModalEndreAktor] = useState(false);
    const [ModalRettefrist, setModalRettefrist] = useState(false);
    const [ModalStansevedtak, setModalStansevedtak] = useState(false);
    const [ValgtInstAarsak, setValgtInstAarsak] = useState(0);
    const [AktivtLoyvenummer, setAktivtLoyvenummer] = useState(0);
    const [AktivtTaklampenummer, setAktivtTaklampenummer] = useState("");
    const [ValgtFabrikat, setValgtFabrikat] = useState(0);
    const [ValgtModell, setValgtModell] = useState(0);
    const [rfdtaklampe, setrfdtaklampe] = useState(true);
    const [rfdomraade, setrfdomraade] = useState(true);
    const [rfddrosjeorg, setrfddrosjeorg] = useState(true);
    const [rfdbilmerke, setrfdbilmerke] = useState(true);
    const [rfdbilaarsmod, setrfdbilaarsmod] = useState(true);
    const [rfdregnr, setrfdregnr] = useState(true);
    const [rfddekkdim, setrfddekkdim] = useState(true);
    const [rfdtaksameterfabrikat, setrfdtaksameterfabrikat] = useState(true);
    const [rfdtaksametermodell, setrfdtaksametermodell] = useState(true);
    const [rfdserienummer, setrfdserienummer] = useState(true);
    const [rfdsoftwareversjon, setrfdsoftwareversjon] = useState(true);
    const [rfdkfaktor, setrfdkfaktor] = useState(true);
    const [rfdjvstrekkode, setrfdjvstrekkode] = useState(true);
    const [visUtmontering, setVisUtmontering] = useState(false);
    const [ModalInstallasjon, setModalInstallasjon] = useState(false);
    const [ModalUtvidRettefrist, setModalUtvidRettefrist] = useState(false);
    const [TaksameterFabrikater, setTaksameterFabrikater] = useState<any[]>([]);
    const [TaksameterModeller, setTaksameterModeller] = useState<any[]>([]);
    const [pbvisdekk, setpbvisdekk] = useState(false);
    const [pbvisswver, setpbvisswver] = useState(false);
    const [pbvissentral, setpbvissentral] = useState(false);
    const [pbvisregnr, setpbvisregnr] = useState(false);
    const nyPlombeTypeRef = useRef<any>([]);
    const nyPlombeNavnRef = useRef<any>([]);
    const [plombeSlett, setPlombeSlett] = useState<any[]>([]);

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    const handleCheckSlettPlombe = (data: any) => {
        if (data.target.checked) {
            plombeSlett.push(data.target.value);
            const ModPlomber = ModalAktivePlomber.map(plomber => {
                if (plomber.PlomberingId === data.target.value) {
                    return {
                        ...plomber,
                        PlombeSkalSlettes: true,
                    };
                } else {
                    return {
                        ...plomber,
                    };
                }
            });
            setModalAktivePlomber(ModPlomber);
        } else {
            const ModPlomber = ModalAktivePlomber.map(plomber => {
                if (plomber.PlomberingId === data.target.value) {
                    return {
                        ...plomber,
                        PlombeSkalSlettes: false,
                    };
                } else {
                    return {
                        ...plomber,
                    };
                }
            });
            setModalAktivePlomber(ModPlomber);
            plombeSlett.splice(plombeSlett.indexOf(data.target.value), 1);
        }
    }

    const handleCheckSlettNyPlombe = (data: any) => {
        if (data.target.checked) {
            setNyePlomber(
                NyePlomber.filter((plomber: any, indexnypl: number) =>
                    indexnypl !== data.target.value
                )
            );
        }
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const handleDrosjesentralSokInst = (e: any) => {
        InstallasjonFormik.handleChange(e);
        handleDrosjesentralSok(e);
    };

    const handleDrosjesentralSokPlombebrudd = (e: any) => {
        PlombebruddFormik.handleChange(e);
        handleDrosjesentralSok(e);
    };

    const handleDrosjesentralSok = (e: any) => {
        if (e.target.value.length === 9) {
            getbrregorg(e.target.value);
        }
    };

    const handleValgtInstAarsak = (e: any) => {
        setValgtInstAarsak(e.target.value);
    }

    const ModalUtmonteringLukk = () => {
        setModalUtmontering(false);
    };

    const ModalHistorikkLukk = () => {
        setModalHistorikk(false);
    };

    const ModalUtmonteringAapne = (InstrumentId: number, InstrumentIndex: number) => {
        setAktivtInstrument(InstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setModalUtmontering(true);
    };

    const ModalHistorikkAapne = (InstrumentId: number, InstrumentIndex: number) => {
        setAktivtInstrument(InstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setModalHistorikk(true);
    };

    const ModalEndreLokasjonLukk = () => {
        setModalEndreLokasjon(false);
    };

    const ModalInstallasjonAapne = (Loyvenummer: number, Taklampenummer: string) => {
        setValgtInstAarsak(0);
        setAktivtLoyvenummer(Loyvenummer);
        setAktivtTaklampenummer(Taklampenummer);
        setModalInstallasjon(true);
    };

    const ModalInstallasjonLukk = () => {
        setModalInstallasjon(false);
    };

    const ModalEndreAktorAapne = () => {
        setModalEndreAktor(true);
    }

    const ModalEndreAktorLukk = () => {
        setModalEndreAktor(false);
    }

    const ModalRettefristAapne = (InstrumentId: number, index: number, rettefrist: number, rettefristdato: string, rettefristsaksnummer: string) => {
        setAktivtInstrument(InstrumentId);
        setAktivtInstrumentIndex(index);
        setAktivRettefrist(rettefrist);
        setAktivRettefristSaksnummer(rettefristsaksnummer);
        setAktivRettefristDato(rettefristdato);
        setModalAktivePlomber(aktor[0]?.instrumenter[index]?.plomberinger);
        getkommuner();
        setModalRettefrist(true);
    }

    const ModalRettefristLukk = () => {
        setModalRettefrist(false);
    }

    const ModalStansevedtakAapne = (InstrumentId: number, index: number, rettefrist: number, rettefristsaksnummer: string) => {
        setAktivtInstrument(InstrumentId);
        setAktivtInstrumentIndex(index);
        setAktivRettefrist(rettefrist);
        setAktivRettefristSaksnummer(rettefristsaksnummer);
        setAktivtLoyvenummer(aktor[0]?.instrumenter[index]?.Loyvenummer);
        setAktivtTaklampenummer(aktor[0]?.instrumenter[index]?.Taklampenummer);
        setAktivtDrosjeomraade(aktor[0]?.instrumenter[index]?.Drosjeomraade);
        setAktivtDrosjesentralOrgNummer(aktor[0]?.instrumenter[index]?.DrosjesentralOrgNummer);
        setAktivtDrosjesentralAktor(aktor[0]?.instrumenter[AktivtInstrumentIndex]?.DrosjesentralAktor);
        setModalStansevedtak(true);
    }

    const ModalStansevedtakLukk = () => {
        setModalStansevedtak(false);
    }

    const ModalPlombebruddAapne = (ValgtInstrumentId: number, InstrumentIndex: number) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setModalAktivePlomber(aktor[0]?.instrumenter[InstrumentIndex]?.plomberinger);
        setModalPlombebrudd(true);
    };

    const ModalUtvidRettefristAapne = (ValgtInstrumentId: number, InstrumentIndex: number, rettefrist: number, rettefristdato: string, rettefristsaksnummer: string) => {
        setAktivtInstrument(ValgtInstrumentId);
        setAktivtInstrumentIndex(InstrumentIndex);
        setAktivRettefrist(rettefrist);
        setAktivRettefristSaksnummer(rettefristsaksnummer);
        setAktivRettefristDato(rettefristdato);
        setModalUtvidRettefrist(true);
    };

    const ModalUtvidRettefristLukk = () => {
        setModalUtvidRettefrist(false);
    };

    const ModalPlombebruddLukk = () => {
        setModalPlombebrudd(false);
    };

    const VelgLokasjon = (event: any, values: any) => {
        if (values && values['id'] !== undefined) {
            setNyttOmraadeId(values['id']);
        } else {
            setNyttOmraadeId(0);
        }
    }

    const VelgTaksameter = (event: any, values: any) => {
        setValgtFabrikat(values['id']);
        gettaximodeller(values['id']);
        setAktivtFabrikat(values['label']);
    }

    const VelgModell = (event: any, values: any) => {
        setValgtModell(values['id']);
        setAktivtModell(values['label']);
    }

    const handlePlombebruddAarsak = (event: any) => {
        setPlombeBruddAarsakValg(event.target.value);
        if (event.target['value'] === '3') {
            setpbvisdekk(true);
        }
        else {
            setpbvisdekk(false);
        }
        if (event.target['value'] === '8') {
            setpbvisswver(true);
        }
        else {
            setpbvisswver(false);
        }
        if (event.target['value'] === '2') {
            setpbvissentral(true);
        }
        else {
            setpbvissentral(false);
        }
        if (event.target['value'] === '12') {
            setpbvisregnr(true);
        }
        else {
            setpbvisregnr(false);
        }
    }

    const handleRettefristAarsak = (event: any) => {
        setRettefristAarsak(event.target.value);
        if (event.target.value === "2") {
            setVisPlomber(false);
            setVisObservasjoner(false);
            setVisUtmontering(true);
        }
        if (event.target.value === "1") {
            setVisPlomber(true);
            setVisUtmontering(false);
            setrfdtaklampe(false);
            setrfdomraade(false);
            setrfddrosjeorg(false);
            setrfdbilmerke(false);
            setrfdbilaarsmod(false);
            setrfdregnr(false);
            setrfddekkdim(false);
            setrfdtaksameterfabrikat(false);
            setrfdtaksametermodell(false);
            setrfdserienummer(false);
            setrfdsoftwareversjon(false);
            setrfdkfaktor(false);
            setrfdjvstrekkode(false);
        }
        if (event.target.value === "3") {
            setVisPlomber(true);
            setVisUtmontering(false);
            setrfdtaklampe(true);
            setrfdomraade(true);
            setrfddrosjeorg(true);
            setrfdbilmerke(true);
            setrfdbilaarsmod(true);
            setrfdregnr(true);
            setrfddekkdim(false);
            setrfdtaksameterfabrikat(true);
            setrfdtaksametermodell(true);
            setrfdserienummer(true);
            setrfdsoftwareversjon(true);
            setrfdkfaktor(false);
            setrfdjvstrekkode(true);
        }
        if (event.target.value === "4") {
            setVisPlomber(true);
            setVisUtmontering(false);
            setrfdtaklampe(true);
            setrfdomraade(true);
            setrfddrosjeorg(true);
            setrfdbilmerke(true);
            setrfdbilaarsmod(true);
            setrfdregnr(true);
            setrfddekkdim(true);
            setrfdtaksameterfabrikat(true);
            setrfdtaksametermodell(true);
            setrfdserienummer(true);
            setrfdsoftwareversjon(false);
            setrfdkfaktor(true);
            setrfdjvstrekkode(true);
        }
        if ((event.target.value !== "1") && (event.target.value !== "3") && (event.target.value !== "4")) {
            setrfdtaklampe(true);
            setrfdomraade(true);
            setrfddrosjeorg(true);
            setrfdbilmerke(true);
            setrfdbilaarsmod(true);
            setrfdregnr(true);
            setrfddekkdim(true);
            setrfdtaksameterfabrikat(true);
            setrfdtaksametermodell(true);
            setrfdserienummer(true);
            setrfdsoftwareversjon(true);
            setrfdkfaktor(true);
            setrfdjvstrekkode(true);
        }
    }

    async function getbrregorg(orgnr: number) {
        await axios.get(APIURL + "/brreg/" + orgnr, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setBrregRespons(response.data);
                    setAktivtDrosjesentralAktor(response.data[0].navn);
                }
                else {
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    async function getkommuner() {
        await axios.get(APIURL + "/taxi/kommuner", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setKommuner(response.data);
                }
                else {
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const ModalEndreLokasjonAapne = async (InstrumentId: number, DrosjeomraadeId: number, DrosjeomraadeNavn: string) => {
        setisLoadingLokasjonOptions(true);
        setAktivtInstrument(InstrumentId);
        setAktivKommuneAktorNavn(DrosjeomraadeNavn);
        getkommuner();
        setModalEndreLokasjon(true);
        setisLoadingLokasjonOptions(false);
    };

    const SendEndreLokasjon = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        ModalEndreLokasjonLukk();
        var formdata = {
            instrumentid: AktivtInstrument,
            lokasjonid: NyttOmraadeId,
        };
        await axios.post(APIURL + "/v2/taxi/endrelokasjon", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    let apires = response.data;
                    console.log(apires);
                    getaktor(orgnr);
                    if (apires === "0") {
                        setSnackMelding("Feil under innsending av endring av primærområde. Kontakt Justervesenet.");
                        setOpenSnackError(true);
                    }
                    if (apires === "1") {
                        setSnackMelding("Endring av primærområde registrert.");
                        setOpenSnack(true);
                    }
                }
                else {
                    setSnackMelding("Feil under innsending av endring av primærområde. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                }
            })
            .catch((e: any) => {
                setSnackMelding("Feil under innsending av endring av primærområde. Kontakt Justervesenet.");
                setOpenSnackError(true);
            });
        setisLoading(false);
    }

    const LeggtilNyPlombe = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        var nypltypet = nyPlombeTypeRef.current.innerText;
        var nypltypei = nypltypet.toString().split("\n");
        var nypltype = nypltypei[1];
        var nyplnavnt = nyPlombeNavnRef.current.innerText;
        var nyplnavni = nyplnavnt.toString().split("\n");
        var nyplnavn = nyplnavni[1];
        var data = new FormData(event.currentTarget);
        var nyplnavnid = data.get('nyplnavn');
        var nypltypeid = data.get('nypltype');
        var nyplnummer = data.get('nyplnummer');
        var nyplplassering = data.get('nyplplassering');
        setNyePlomber([...NyePlomber, { nyplnavnid: nyplnavnid, nyplnavn: nyplnavn, nypltype: nypltype, nypltypeid: nypltypeid, nyplnummer: nyplnummer, nyplplassering: nyplplassering }]);
        event.currentTarget.reset();
    }

    const UtmonteringValidationSchema = Yup.object({
        kommentar: Yup.string().matches(/^[a-zA-ZæøåÆØÅ0-9@,. ?_-]+$/, 'Ugyldige tegn i kommentarfeltet. Gyldige spesialtegn er: .,@-_?')
    });

    const UtmonteringFormik = useFormik({
        initialValues: {
            utmontertdato: moment(new Date()).format('YYYY-MM-DD'),
            loyvenr: AktivtLoyvenummer,
            loyvestatus: "",
            kommentar: ""
        },
        validationSchema: UtmonteringValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoading(true);
            ModalUtmonteringLukk();
            var data = new FormData();
            data.append('instrumentid', AktivtInstrument.toString());
            data.append('utmontertdato', values.utmontertdato);
            data.append('loyvestatus', values.loyvestatus);
            data.append('kommentar', values.kommentar);
            await axios.post(APIURL + "/v2/taxi/utmontering", data, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    if (response.status === 200) {
                        let apires = response.data;
                        getaktor(orgnr);
                        if (apires === "0") {
                            setSnackMelding("Feil under innsending av utmontering. Kontakt Justervesenet.");
                            setOpenSnackError(true);
                        }
                        if (apires === "1") {
                            setSnackMelding("Taksameteret er registrert utmontert");
                            setOpenSnack(true);
                        }
                    }
                    else {
                        setSnackMelding("Feil under innsending av utmontering. Kontakt Justervesenet.");
                        setOpenSnackError(true);
                    }
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under innsending av melding om utmontering");
                    setOpenSnackError(true);
                });
            resetForm();
            setisLoading(false);
        },
    });

    const SendUtvidRettefrist = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        ModalUtvidRettefristLukk();
        var data = new FormData(event.currentTarget);
        var formdata = {
            instrumentid: AktivtInstrument,
            rettefristid: AktivRettefrist,
            kontaktpersonaktorid: sessionStorage.getItem('jvaktorid'),
            kontaktpersonaktororg: sessionStorage.getItem('jvaktororgnummer'),
            kontaktpersonaktornavn: sessionStorage.getItem('jvaktornavn'),
            nydato: data.get('nydato'),
            kommentar: data.get('kommentar'),
        };
        await axios.post(APIURL + "/v2/taxi/utvidetbrukstillatelse", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    let apires = response.data;
                    getaktor(orgnr);
                    if (apires === "0") {
                        setSnackMelding("Feil under innsending av søknad om midlertidig brukstillatelse. Kontakt Justervesenet.");
                        setOpenSnackError(true);
                    }
                    if (apires === "1") {
                        setSnackMelding("Søknad om utvidet midlertidig brukstillatelse sendt");
                        setOpenSnack(true);
                    }
                }
                else {
                    setSnackMelding("Feil under innsending av søknad om midlertidig brukstillatelse. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                }
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding("Feil under innsending av søknad om midlertidig brukstillatelse");
                setOpenSnackError(true);
            });
        setisLoading(false);
    }

    const sendAktorOppdaterKontaktinfo = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        ModalEndreAktorLukk();
        var data = new FormData(event.currentTarget);
        let aktorid = "" + AktivAktorId;
        data.append('aktorid', aktorid);
        await axios.post(APIURL + "/aktor/oppdaterkontakt", data, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getaktor(orgnr);
                    setSnackMelding("Kontaktopplysninger oppdatert");
                    setOpenSnack(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
                setSnackMelding("Feil under oppdatering av konktaktopplysninger");
                setOpenSnackError(true);
            });
        setisLoading(false);
    }

    const InstallasjonValidationSchema = Yup.object({
        instdato: Yup.date().required('Installationsdato er obligatorisk'),
        loyvenr: Yup.string().min(3, 'Må ha minimum 5 tegn').max(30, 'Maks 15 tegn').required('Løyvenummer er obligatorisk'),
        drosjesentralorg: Yup.string().matches(/^$|^\d{9}$/, 'Må være tom eller ha nøyaktig 8 siffer'),
        bilmerke: Yup.string().required('Bilmerke er obligatorisk').min(3, 'Må ha minimum 3 bokstaver').max(30, 'Maks 30 bokstaver'),
        aarsmodell: Yup.string().required('Årsmodell er obligatorisk').matches(/^[0-9]{4}$/, 'Må være et gyldig årstall på 4 tall'),
        regnr: Yup.string().matches(/^[A-Za-z][A-Za-z]\d{5}$/, 'Må være to bokstaver og 5 siffer uten mellomrom').required('Registreringsnummer er obligatorisk'),
        dekkdim: Yup.string().matches(/^\d{3}\/\d{2}-\d{2}$/, 'Ugyldig format, eksempel: 235/45-18').required('Dekkdimensjon er obligatorisk'),
        serienr: Yup.string().min(3, 'Må ha minimum 3 tegn').max(30, 'Maks 50 tegn').required('Serienummer er obligatorisk'),
        swver: Yup.string().required('Software versjon er obligatorisk').min(2, 'Må ha minimum 2 bokstaver/tall').max(30, 'Maks 30 tegn'),
        kfaktor: Yup.string().matches(/^\d{1,6}$/, 'K-Faktor må være mellom 1 og 6 siffer').required('K-Faktor er obligatorisk'),
        jvstrekkode: Yup.string().matches(/^\d{1,50}$/, 'Justervesenets strekkode må være mellom 1 og 50 siffer'),
        ekstraopplysninger: Yup.string().matches(/^[a-zA-ZæøåÆØÅ0-9@,. ?_-]+$/, 'Ugyldige tegn i kommentarfeltet. Gyldige spesialtegn er: .,@-_?')
    });

    const InstallasjonFormik = useFormik({
        initialValues: {
            instdato: moment(new Date()).format('YYYY-MM-DD'),
            loyvenr: AktivtLoyvenummer,
            taklampe: AktivtTaklampenummer,
            drosjesentralorg: "",
            bilmerke: "",
            aarsmodell: "",
            regnr: "",
            dekkdim: "",
            serienr: "",
            swver: "",
            kfaktor: "",
            jvstrekkode: "",
            ekstraopplysninger: "",
            instaarsak: ""
        },
        validationSchema: InstallasjonValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoading(true);
            ModalInstallasjonLukk();
            const instformdata = new FormData();
            let aktorid = "" + AktivAktorId;
            let fabrikatid = "" + ValgtFabrikat;
            let modellid = "" + ValgtModell;
            instformdata.append('instaarsak', ValgtInstAarsak.toString());
            instformdata.append('instdato', values.instdato);
            instformdata.append('loyvenummer', values.loyvenr.toString());
            instformdata.append('taklampe', values.taklampe);
            instformdata.append('drosjesentralorg', values.drosjesentralorg);
            instformdata.append('bilmerke', values.bilmerke);
            instformdata.append('aarsmodell', values.aarsmodell);
            instformdata.append('regnr', values.regnr);
            instformdata.append('dekkdim', values.dekkdim);
            instformdata.append('serienr', values.serienr);
            instformdata.append('swver', values.swver);
            instformdata.append('kfaktor', values.kfaktor);
            instformdata.append('jvstrekkode', values.jvstrekkode);
            instformdata.append('aktorid', aktorid);
            instformdata.append('orgnr', orgnr || "");
            instformdata.append('fabrikatid', fabrikatid);
            instformdata.append('modellid', modellid);
            instformdata.append('ekstraopplysninger', values.ekstraopplysninger);
            instformdata.append("nyeplomber", JSON.stringify(NyePlomber));
            instformdata.append("aktoridomraade", NyttOmraadeId.toString());
            instformdata.append("aktoridsentral", BrregRespons[0]?.orgnr.toString() || "");
            instformdata.append("kontaktpersonaktorid", sessionStorage.getItem('jvaktorid') || "0");
            instformdata.append("kontaktpersonaktororg", sessionStorage.getItem('jvaktororgnummer') || "0");
            instformdata.append("kontaktpersonaktornavn", sessionStorage.getItem('jvaktornavn') || "-");
            await axios.post(APIURL + "/v2/taxi/installasjon", instformdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    if (response.status === 200) {
                        let apires = response.data;
                        getaktor(orgnr);
                        if (apires === "0") {
                            setSnackMelding("Feil under innsending av melding om installasjon. Kontakt Justervesenet.");
                            setOpenSnackError(true);
                        }
                        if (apires === "1") {
                            setSnackMelding("Installasjon av taksameter er registrert");
                            setOpenSnack(true);
                        }
                    }
                    else {
                        setSnackMelding("Feil under innsending av melding om installasjon. Kontakt Justervesenet.");
                        setOpenSnackError(true);
                    }
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under innsending av melding om utmontering");
                    setOpenSnackError(true);
                });
            setisLoading(false);
            setNyePlomber([]);
        },
    });

    const PlombebruddValidationSchema = Yup.object({
        plombebrudddato: Yup.date().required('Plombebrudddato er obligatorisk'),
        drosjesentralorg: Yup.string().matches(/^$|^\d{9}$/, 'Må være tom eller ha nøyaktig 8 siffer'),
        pbdekk: Yup.string().matches(/^\d{3}\/\d{2}-\d{2}$/, 'Ugyldig format, eksempel: 235/45-18'),
        pbswversjon: Yup.string().min(2, 'Må ha minimum 2 bokstaver/tall').max(30, 'Maks 30 tegn'),
        pbkfaktor: Yup.string().matches(/^\d{1,6}$/, 'K-Faktor må være mellom 1 og 6 siffer'),
        kommentar: Yup.string().matches(/^[a-zA-ZæøåÆØÅ0-9@,. ?_-]+$/, 'Ugyldige tegn i kommentarfeltet. Gyldige spesialtegn er: .,@-_?')
    });

    const PlombebruddFormik = useFormik({
        initialValues: {
            plombebrudddato: moment(new Date()).format('YYYY-MM-DD'),
            loyvenr: AktivtLoyvenummer,
            pbswversjon: "",
            pbkfaktor: "",
            pbdekk: "",
            plombebruddaarsak: "",
            kommentar: "",
            drosjesentralorg: "",
            pbregnr: ""
        },
        validationSchema: PlombebruddValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoading(true);
            ModalPlombebruddLukk();
            var data = new FormData();
            data.append("instrumentid", AktivtInstrument.toString());
            data.append('plombebruddaarsak', plombeBruddAarsakValg.toString());
            data.append('pbswversjon', values.pbswversjon);
            data.append('pbkfaktor', values.pbkfaktor);
            data.append('pbdekk', values.pbdekk);
            data.append('plombebrudddato', values.plombebrudddato);
            data.append('kommentar', values.kommentar);
            data.append('pbregnr', values.pbregnr);
            data.append('drosjesentralorg', values.drosjesentralorg);
            data.append("plombeslett", JSON.stringify(plombeSlett));
            data.append("nyeplomber", JSON.stringify(NyePlomber));
            await axios.post(APIURL + "/v2/taxi/plombebrudd", data, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    if (response.status === 200) {
                        let apires = response.data;
                        getaktor(orgnr);
                        if (apires === "0") {
                            setSnackMelding("Feil oppstod under innmelding av plombebrudd. Kontakt Justervesenet.");
                            setOpenSnackError(true);
                        }
                        if (apires === "1") {
                            setSnackMelding("Melding om plombebrudd registrert");
                            setOpenSnack(true);
                        }
                        resetForm();
                        setPlombeBruddAarsakValg(1);
                    }
                    else {
                        setSnackMelding("Feil oppstod under innmelding av plombebrudd. Kontakt Justervesenet.");
                        setOpenSnackError(true);
                    }
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil under innsending av melding om utmontering");
                    setOpenSnackError(true);
                });

            setisLoading(false);
            setNyePlomber([]);
            setPlombeSlett([]);
        },
    });

    const RettefristValidationSchema = Yup.object({
    });

    const RettefristFormik = useFormik({
        initialValues: {
            rettingdato: moment(new Date()).format('YYYY-MM-DD'),
            loyvenummer: AktivtLoyvenummer,
            rettingbeskrivelse: "",
            rettefristaarsak: "12",
            drosjesentralorg: "",
            ekstraopplysninger: "",
            jvstrekkode: "",
            loyvestatus: "",
            kfaktor: "",
            swver: "",
            serienr: "",
            modell: "",
            fabrikat: "",
            dekkdim: "",
            regnr: "",
            aarsmodell: "",
            bilmerke: "",
            kommune: "",
            taklampe: ""
        },
        validationSchema: RettefristValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoading(true);
            ModalRettefristLukk();
            var data = new FormData();
            data.append("instrumentid", AktivtInstrument.toString());
            data.append("rettefristid", AktivRettefrist.toString());
            data.append("rettefristsaksnummer", AktivRettefristSaksnummer.toString());
            data.append('rettefristaarsak', rettefristAarsak.toString());
            data.append('rettingbeskrivelse', values.rettingbeskrivelse);
            data.append('rettingdato', values.rettingdato);
            data.append('loyvestatus', values.loyvestatus);
            data.append('kommentar', values.ekstraopplysninger);
            data.append('kfaktor', values.kfaktor);
            data.append('dekkdim', values.dekkdim);
            data.append('swver', values.swver);
            data.append('drosjesentralorg', values.drosjesentralorg);
            data.append('jvstrekkode', values.jvstrekkode);
            data.append('serienr', values.serienr);
            data.append('modell', values.modell);
            data.append('fabrikat', values.fabrikat);
            data.append('modellid', ValgtModell.toString());
            data.append('fabrikatid', ValgtFabrikat.toString());
            data.append('regnr', values.regnr);
            data.append('aarsmodell', values.aarsmodell);
            data.append('bilmerke', values.bilmerke);
            data.append('kommune', values.kommune);
            data.append('taklampe', values.taklampe);
            data.append("aktoridomraade", NyttOmraadeId.toString());
            data.append("plombeslett", JSON.stringify(plombeSlett));
            data.append("nyeplomber", JSON.stringify(NyePlomber));
            await axios.post(APIURL + "/v2/taxi/rettefrist", data, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    if (response.status === 200) {
                        let apires = response.data;
                        getaktor(orgnr);
                        if (apires === "0") {
                            setSnackMelding("Feil oppstod under innmelding av rettefrist. Kontakt Justervesenet.");
                            setOpenSnackError(true);
                        }
                        if (apires === "1") {
                            setSnackMelding("Melding om utført rettefrist registrert");
                            setOpenSnack(true);
                        }
                        resetForm();
                        setRettefristAarsak(0);
                    }
                    else {
                        setSnackMelding("Feil oppstod under innmelding av rettefrist. Kontakt Justervesenet.");
                        setOpenSnackError(true);
                    }
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil oppstod under innmelding av rettefrist. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            setisLoading(false);
            setNyePlomber([]);
            setPlombeSlett([]);
        },
    });

    const StansevedtakValidationSchema = Yup.object({
    });

    const StansevedtakFormik = useFormik({
        initialValues: {
            rettingdato: moment(new Date()).format('YYYY-MM-DD'),
            loyvenummer: aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Loyvenummer,
            rettingbeskrivelse: "",
            rettefristaarsak: "12",
            drosjesentralorg: "",
            ekstraopplysninger: "",
            jvstrekkode: "",
            loyvestatus: "",
            kfaktor: "",
            swver: "",
            serienr: "",
            modell: "",
            fabrikat: "",
            dekkdim: "",
            regnr: "",
            aarsmodell: "",
            bilmerke: "",
            kommune: "",
            taklampe: ""
        },
        validationSchema: StansevedtakValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            setisLoading(true);
            ModalStansevedtakLukk();
            var data = new FormData();
            data.append("instrumentid", AktivtInstrument.toString());
            data.append("rettefristid", AktivRettefrist.toString());
            data.append("rettefristsaksnummer", AktivRettefristSaksnummer.toString());
            data.append('rettefristaarsak', rettefristAarsak.toString());
            data.append('rettingbeskrivelse', values.rettingbeskrivelse);
            data.append('rettingdato', values.rettingdato);
            data.append('loyvestatus', values.loyvestatus);
            data.append('kommentar', values.ekstraopplysninger);
            data.append('kfaktor', AktivtKFaktor);
            data.append('dekkdim', AktivtDekkdim);
            data.append('swver', AktivtSoftwareversjon);
            data.append('drosjesentralorg', AktivtDrosjesentralOrgNummer);
            data.append('jvstrekkode', AktivtStrekkode);
            data.append('serienr', AktivtSerienummer);
            data.append('modell', AktivtModell);
            data.append('fabrikat', AktivtFabrikat);
            data.append('modellid', ValgtModell.toString());
            data.append('fabrikatid', ValgtFabrikat.toString());
            data.append('regnr', AktivtRegnr);
            data.append('aarsmodell', AktivtAarsmodell);
            data.append('bilmerke', AktivtBilmerke);
            data.append('kommune', values.kommune);
            data.append('taklampe', AktivtTaklampenummer);
            data.append("aktoridomraade", NyttOmraadeId.toString());
            data.append("plombeslett", JSON.stringify(plombeSlett));
            data.append("nyeplomber", JSON.stringify(NyePlomber));
            await axios.post(APIURL + "/v2/taxi/stansevedtak", data, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
                .then((response: any) => {
                    if (response.status === 200) {
                        let apires = response.data;
                        getaktor(orgnr);
                        if (apires === "0") {
                            setSnackMelding("Feil oppstod under innmelding av retting av stansevedtak. Kontakt Justervesenet.");
                            setOpenSnackError(true);
                        }
                        if (apires === "1") {
                            setSnackMelding("Melding om retting av stansevedtak registrert");
                            setOpenSnack(true);
                        }
                        resetForm();
                        setRettefristAarsak(0);
                    }
                    else {
                        setSnackMelding("Feil oppstod under innmelding av retting av stansevedtak. Kontakt Justervesenet.");
                        setOpenSnackError(true);
                    }
                })
                .catch((e: Error) => {
                    setSnackMelding("Feil oppstod under innmelding av retting av stansevedtak. Kontakt Justervesenet.");
                    setOpenSnackError(true);
                });
            setisLoading(false);
            setNyePlomber([]);
            setPlombeSlett([]);
        },
    });

    const handleChangeSVDrosjesentralOrgNummer = (event: { target: { value: any; }; }) => {
        StansevedtakFormik.handleChange(event);
        setAktivtDrosjesentralOrgNummer(event.target.value);
        handleDrosjesentralSok(event);
    };

    const handleChangeSVAktivtBilmerke = (event: { target: { value: any; }; }) => {
        StansevedtakFormik.handleChange(event);
        setAktivtBilmerke(event.target.value);
    };

    const handleChangeSVAktivtAarsmodell = (event: { target: { value: any; }; }) => {
        StansevedtakFormik.handleChange(event);
        setAktivtAarsmodell(event.target.value);
    };

    const handleChangeSVAktivtRegnr = (event: { target: { value: any; }; }) => {
        StansevedtakFormik.handleChange(event);
        setAktivtRegnr(event.target.value);
    };

    const handleChangeSVAktivtDekkdim = (event: { target: { value: any; }; }) => {
        StansevedtakFormik.handleChange(event);
        setAktivtDekkdim(event.target.value);
    };

    const handleChangeSVAktivtSerienummer = (event: { target: { value: any; }; }) => {
        StansevedtakFormik.handleChange(event);
        setAktivtSerienummer(event.target.value);
    };

    const handleChangeSVAktivtSoftwareversjon = (event: { target: { value: any; }; }) => {
        StansevedtakFormik.handleChange(event);
        setAktivtSoftwareversjon(event.target.value);
    };

    const handleChangeSVAktivtKFaktor = (event: { target: { value: any; }; }) => {
        StansevedtakFormik.handleChange(event);
        setAktivtKFaktor(event.target.value);
    };

    const handleChangeSVAktivtStrekkode = (event: { target: { value: any; }; }) => {
        StansevedtakFormik.handleChange(event);
        setAktivtStrekkode(event.target.value);
    };

    const SendInstallasjonTurvogn = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        ModalInstallasjonLukk();
        var data = new FormData(event.currentTarget);
        let aktorid = "" + AktivAktorId;
        let fabrikatid = "" + ValgtFabrikat;
        let modellid = "" + ValgtModell;
        data.append('aktorid', aktorid);
        data.append('fabrikatid', fabrikatid);
        data.append('modellid', modellid);
        data.append("nyeplomber", JSON.stringify(NyePlomber));
        data.append("aktoridomraade", NyttOmraadeId.toString());
        data.append("aktoridsentral", BrregRespons[0]?.orgnr.toString() || "");
        data.append("kontaktpersonaktorid", sessionStorage.getItem('jvaktorid') || "0");
        data.append("kontaktpersonaktororg", sessionStorage.getItem('jvaktororgnummer') || "0");
        data.append("kontaktpersonaktornavn", sessionStorage.getItem('jvaktornavn') || "-");
        await axios.post(APIURL + "/v2/taxi/installasjon", data, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getaktor(orgnr);
                    setSnackMelding("Installasjon av taksameter er registrert");
                    setOpenSnack(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
                setSnackMelding("Feil under innsending av melding om installasjon");
                setOpenSnackError(true);
            });
        setisLoading(false);
        setNyePlomber([]);
    }

    function getaktor(orgnr: any) {
        axios.get(APIURL + "/taxi/detaljer/" + orgnr, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setAktor(response.data);
                    setAktivAktorId(response.data[0].AktorId);
                    getkommuner();
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: Error) => {
                setisLoading(false);
            });
    }

    function gettaxifabrikater() {
        axios.get(APIURL + "/taxi/fabrikater", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setTaksameterFabrikater(response.data);
                    console.log(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: Error) => {
                setisLoading(false);
            });
    }

    function gettaximodeller(fabrikatid: number) {
        axios.get(APIURL + "/taxi/modeller/" + fabrikatid, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setTaksameterModeller(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: Error) => {
                setisLoading(false);
            });
    }

    useEffect(() => {
        document.title = 'Taksameter - Justervesenet';
        getaktor(orgnr);
        gettaxifabrikater();
        setisLoading(false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        InstallasjonFormik.setValues({ ...InstallasjonFormik.values, loyvenr: AktivtLoyvenummer, taklampe: AktivtTaklampenummer });
        PlombebruddFormik.setValues({ ...PlombebruddFormik.values, loyvenr: AktivtLoyvenummer });
        UtmonteringFormik.setValues({ ...UtmonteringFormik.values, loyvenr: AktivtLoyvenummer });
        // eslint-disable-next-line
    }, [AktivtLoyvenummer]);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                            <Typography sx={{ pl: 2 }}>
                                <Text tid={"oppdaterermelding"} />
                            </Typography>
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSnackError} autoHideDuration={60000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>

                        <Dialog
                            fullScreen
                            open={ModalInstallasjon}
                            onClose={ModalInstallasjonLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        <Text tid={"instkontforloyve"} /> {AktivtLoyvenummer}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalInstallasjonLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                {((aktor[0]?.Epost !== "") && (aktor[0]?.Mobilnummer !== "")) ?
                                    <div>
                                        <Box component="form" id="installasjonform" onSubmit={InstallasjonFormik.handleSubmit}>
                                            <Grid container sx={{ mb: 2 }}>
                                                <FormControl required>
                                                    <FormLabel id="instaarsaklabel"><Text tid={"aarsak"} /></FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="instaarsaklabel"
                                                        name="instaarsak"
                                                        onChange={(e) => handleValgtInstAarsak(e)}
                                                    >
                                                        <FormControlLabel value="3" control={<Radio />} label={Text({ tid: "loyvetsettesidrift" })} />
                                                        <FormControlLabel value="1" control={<Radio />} label={Text({ tid: "nyttkjoretoy" })} />
                                                        <FormControlLabel value="2" control={<Radio />} label={Text({ tid: "nytttaksameter" })} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            {(ValgtInstAarsak !== 0) &&
                                                <div>
                                                    <Grid container sx={{ mb: 2 }}>
                                                        <TextField
                                                            size="small"
                                                            type="date"
                                                            id="instdato"
                                                            label={Text({ tid: "installasjonsdato" })}
                                                            name="instdato"
                                                            defaultValue={InstallasjonFormik.values.instdato}
                                                            onChange={InstallasjonFormik.handleChange}
                                                            onBlur={InstallasjonFormik.handleBlur}
                                                            error={InstallasjonFormik.touched.instdato && Boolean(InstallasjonFormik.errors.instdato)}
                                                            helperText={InstallasjonFormik.touched.instdato && InstallasjonFormik.errors.instdato}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid container sx={{ mb: 2 }}>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                id="loyvenummer"
                                                                label={Text({ tid: "loyvenummer" })}
                                                                name="loyvenummer"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.loyvenr}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.loyvenr && Boolean(InstallasjonFormik.errors.loyvenr)}
                                                                helperText={InstallasjonFormik.touched.loyvenr && InstallasjonFormik.errors.loyvenr}
                                                                required
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                id="taklampe"
                                                                label={Text({ tid: "taklampe" })}
                                                                name="taklampe"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.taklampe}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.taklampe && Boolean(InstallasjonFormik.errors.taklampe)}
                                                                helperText={InstallasjonFormik.touched.taklampe && InstallasjonFormik.errors.taklampe}
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} sx={{ pr: 2 }}>
                                                            <Autocomplete
                                                                size="small"
                                                                ListboxProps={{ style: { maxHeight: 150 } }}
                                                                disablePortal
                                                                id="kommune"
                                                                onChange={(e, v) => VelgLokasjon(e, v)}
                                                                options={kommuner}
                                                                renderInput={(params) => <TextField
                                                                    {...params}
                                                                    label={Text({ tid: "primaeromraade" })}
                                                                    name="kommune"
                                                                    required
                                                                />}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{ mb: 2 }}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                type="number"
                                                                fullWidth
                                                                id="drosjesentralorg"
                                                                label={Text({ tid: "drosjesentralorg" })}
                                                                name="drosjesentralorg"
                                                                onChange={(e) => handleDrosjesentralSokInst(e)}
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.drosjesentralorg}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.drosjesentralorg && Boolean(InstallasjonFormik.errors.drosjesentralorg)}
                                                                helperText={InstallasjonFormik.touched.drosjesentralorg && InstallasjonFormik.errors.drosjesentralorg}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                id="drosjesentralnavn"
                                                                label={Text({ tid: "drosjesentralnavn" })}
                                                                name="drosjesentralnavn"
                                                                value={BrregRespons[0]?.navn}
                                                                defaultValue=" "
                                                                sx={{ pr: 2 }}
                                                                disabled
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{ mb: 2 }}>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                id="bilmerke"
                                                                label={Text({ tid: "bilmerke" })}
                                                                name="bilmerke"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.bilmerke}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.bilmerke && Boolean(InstallasjonFormik.errors.bilmerke)}
                                                                helperText={InstallasjonFormik.touched.bilmerke && InstallasjonFormik.errors.bilmerke}
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size="small"
                                                                type="number"
                                                                fullWidth
                                                                id="aarsmodell"
                                                                label={Text({ tid: "aarsmodell" })}
                                                                name="aarsmodell"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.aarsmodell}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.aarsmodell && Boolean(InstallasjonFormik.errors.aarsmodell)}
                                                                helperText={InstallasjonFormik.touched.aarsmodell && InstallasjonFormik.errors.aarsmodell}
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                id="regnr"
                                                                label={Text({ tid: "regnr" })}
                                                                name="regnr"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.regnr}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.regnr && Boolean(InstallasjonFormik.errors.regnr)}
                                                                helperText={InstallasjonFormik.touched.regnr && InstallasjonFormik.errors.regnr}
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                id="dekkdim"
                                                                label={Text({ tid: "dekkdim" })}
                                                                name="dekkdim"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.dekkdim}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.dekkdim && Boolean(InstallasjonFormik.errors.dekkdim)}
                                                                helperText={InstallasjonFormik.touched.dekkdim && InstallasjonFormik.errors.dekkdim}
                                                                required
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{ mb: 2 }}>
                                                        <Grid item xs={4} sx={{ pr: 2 }}>
                                                            <Autocomplete
                                                                size="small"
                                                                ListboxProps={{ style: { maxHeight: 150 } }}
                                                                disablePortal
                                                                id="fabrikat"
                                                                onChange={(e, v) => VelgTaksameter(e, v)}
                                                                options={TaksameterFabrikater}
                                                                renderInput={(params) => <TextField
                                                                    {...params}
                                                                    label={Text({ tid: "taksameterfabrikat" })}
                                                                    name="fabrikat"
                                                                    required
                                                                />}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} pr={2}>
                                                            <Autocomplete
                                                                size="small"
                                                                ListboxProps={{ style: { maxHeight: 150 } }}
                                                                disablePortal
                                                                id="modell"
                                                                onChange={(e, v) => VelgModell(e, v)}
                                                                options={TaksameterModeller}
                                                                renderInput={(params) => <TextField
                                                                    {...params}
                                                                    label={Text({ tid: "taksametermodell" })}
                                                                    name="modell"
                                                                    required
                                                                />}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                id="serienr"
                                                                label={Text({ tid: "serienr" })}
                                                                name="serienr"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.serienr}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.serienr && Boolean(InstallasjonFormik.errors.serienr)}
                                                                helperText={InstallasjonFormik.touched.serienr && InstallasjonFormik.errors.serienr}
                                                                required
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{ mb: 2 }}>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                id="swver"
                                                                label={Text({ tid: "softwareversjon" })}
                                                                name="swver"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.swver}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.swver && Boolean(InstallasjonFormik.errors.swver)}
                                                                helperText={InstallasjonFormik.touched.swver && InstallasjonFormik.errors.swver}
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                id="kfaktor"
                                                                label={Text({ tid: "kfaktor" })}
                                                                name="kfaktor"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.kfaktor}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.kfaktor && Boolean(InstallasjonFormik.errors.kfaktor)}
                                                                helperText={InstallasjonFormik.touched.kfaktor && InstallasjonFormik.errors.kfaktor}
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                size="small"
                                                                fullWidth
                                                                id="jvstrekkode"
                                                                label={Text({ tid: "jvstrekkode" })}
                                                                name="jvstrekkode"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.jvstrekkode}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.jvstrekkode && Boolean(InstallasjonFormik.errors.jvstrekkode)}
                                                                helperText={InstallasjonFormik.touched.jvstrekkode && InstallasjonFormik.errors.jvstrekkode}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container sx={{ mb: 2 }}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                multiline
                                                                rows="2"
                                                                size="small"
                                                                fullWidth
                                                                id="ekstraopplysninger"
                                                                label={Text({ tid: "kommentarer" })}
                                                                name="ekstraopplysninger"
                                                                sx={{ pr: 2 }}
                                                                defaultValue={InstallasjonFormik.values.ekstraopplysninger}
                                                                onChange={InstallasjonFormik.handleChange}
                                                                onBlur={InstallasjonFormik.handleBlur}
                                                                error={InstallasjonFormik.touched.ekstraopplysninger && Boolean(InstallasjonFormik.errors.ekstraopplysninger)}
                                                                helperText={InstallasjonFormik.touched.ekstraopplysninger && InstallasjonFormik.errors.ekstraopplysninger}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Typography>
                                                        <Text tid={"plombermin1"} />
                                                    </Typography>


                                                    <TableContainer sx={{ mb: 3 }}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell className='jvtablehead'><Text tid={"navn"} /></TableCell>
                                                                    <TableCell className='jvtablehead'><Text tid={"type"} /></TableCell>
                                                                    <TableCell className='jvtablehead'><Text tid={"nummer"} /></TableCell>
                                                                    <TableCell className='jvtablehead'><Text tid={"plassering"} /></TableCell>
                                                                    <TableCell className='jvtablehead'><Text tid={"valg"} /></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {NyePlomber && NyePlomber.map(({ nyplnavn, nyplnummer, nypltype, nyplplassering }: any, indexnypl: number) => {
                                                                    return <TableRow key={indexnypl} sx={{ mb: 1 }}>
                                                                        <TableCell className='jvtablerow'>
                                                                            {nyplnavn}
                                                                        </TableCell>
                                                                        <TableCell className='jvtablerow'>{nypltype}</TableCell>
                                                                        <TableCell className='jvtablerow'>{nyplnummer}</TableCell>
                                                                        <TableCell className='jvtablerow'>{nyplplassering}</TableCell>
                                                                        <TableCell className='jvtablerow'>
                                                                            <FormControlLabel
                                                                                key={"nyplid_" + indexnypl}
                                                                                value={indexnypl}
                                                                                control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} />}
                                                                                checked={false}
                                                                                label=""
                                                                                onChange={handleCheckSlettNyPlombe}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            }
                                        </Box>
                                        {(ValgtInstAarsak !== 0) &&
                                            <div>
                                                <Typography>
                                                    <Text tid={"leggtilnyplombe"} />
                                                </Typography>
                                                <Box component="form" id="plombebruddform" onSubmit={LeggtilNyPlombe}>
                                                    <Grid container>
                                                        <Grid item xs={2} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                            <TextField
                                                                size="small"
                                                                select
                                                                fullWidth
                                                                label="Plombenavn"
                                                                name="nyplnavn"
                                                                id="nyplnavn"
                                                                defaultValue={"1"}
                                                                ref={nyPlombeNavnRef}
                                                                required
                                                            >
                                                                <MenuItem value="1">
                                                                    {Text({ tid: "taksameter" })}
                                                                </MenuItem>
                                                                <MenuItem value="2">
                                                                    {Text({ tid: "spenning" })}
                                                                </MenuItem>
                                                                <MenuItem value="3">
                                                                    {Text({ tid: "koblingsboks" })}
                                                                </MenuItem>
                                                                <MenuItem value="4">
                                                                    {Text({ tid: "display" })}
                                                                </MenuItem>
                                                                <MenuItem value="5">
                                                                    {Text({ tid: "kalibreringskode" })}
                                                                </MenuItem>
                                                                <MenuItem value="6">
                                                                    {Text({ tid: "indikator" })}
                                                                </MenuItem>
                                                                <MenuItem value="7">
                                                                    {Text({ tid: "veiecelle" })}
                                                                </MenuItem>
                                                                <MenuItem value="8">
                                                                    {Text({ tid: "annet" })}
                                                                </MenuItem>
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={2} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                            <TextField
                                                                size="small"
                                                                select
                                                                fullWidth
                                                                label="Type"
                                                                name="nypltype"
                                                                id="nypltype"
                                                                defaultValue={"2"}
                                                                ref={nyPlombeTypeRef}
                                                                required
                                                            >
                                                                <MenuItem value="1" aria-label='Elektronisk' aria-labelledby='Elektronisk'>
                                                                    {Text({ tid: "elektronisk" })}
                                                                </MenuItem>
                                                                <MenuItem value="2" aria-label='Fysisk' aria-labelledby='Fysisk'>
                                                                    {Text({ tid: "fysisk" })}
                                                                </MenuItem>
                                                            </TextField>
                                                        </Grid>
                                                        <Grid item xs={3} sx={{ mb: 1, pr: 1 }}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                id="nyplnummer"
                                                                label={Text({ tid: "plombenummer" })}
                                                                name="nyplnummer"
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} sx={{ mb: 1 }}>
                                                            <TextField
                                                                size="small"
                                                                margin="normal"
                                                                fullWidth
                                                                id="nyplplassering"
                                                                label={Text({ tid: "plassering" })}
                                                                name="nyplplassering"
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1} sx={{ ml: 1, pt: 2 }}>
                                                            <Button
                                                                type="submit"
                                                                variant="outlined"
                                                                color="primary"
                                                            >
                                                                <AddCircleIcon className='jvbtnicon' />
                                                                {Text({ tid: "leggtil" })}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                                <DialogActions>
                                                    <Button
                                                        size="small"
                                                        type="submit"
                                                        form="installasjonform"
                                                        variant="outlined"
                                                        color="success"
                                                        disabled={!NyePlomber.length}
                                                    >
                                                        <SaveIcon className='jvbtnicon' />
                                                        {Text({ tid: "sendmelding" })}
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        color="error"
                                                        onClick={ModalInstallasjonLukk}
                                                    >
                                                        <CloseIcon className='jvbtnicon' />
                                                        {Text({ tid: "avbryt" })}
                                                    </Button>
                                                </DialogActions>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <Alert variant="outlined" severity="error" sx={{ mb: 2 }}>
                                            {Text({ tid: "manglerkontaktinfoloyve" })}
                                        </Alert>
                                        <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                            {Text({ tid: "manglerkontaktinfoloyvepersonvern" })}
                                        </Alert>
                                        <Box component="form" id="kontaktoppdateringform" onSubmit={sendAktorOppdaterKontaktinfo}>
                                            <Grid container>
                                                <Grid item xs={12} p={2}>
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        id="aktorepost"
                                                        label={Text({ tid: "epost" })}
                                                        name="epost"
                                                        defaultValue={aktor[0]?.Epost}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12} p={2}>
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        id="aktormobil"
                                                        label={Text({ tid: "mobiltelefon" })}
                                                        name="mobil"
                                                        defaultValue={aktor[0]?.Mobilnummer}
                                                        required
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <DialogActions>
                                            <Button
                                                size="small"
                                                type="submit"
                                                form="kontaktoppdateringform"
                                                variant="outlined"
                                                color="success"
                                            >
                                                <SaveIcon className='jvbtnicon' />
                                                {Text({ tid: "oppdater" })}
                                            </Button>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                color="error"
                                                onClick={ModalInstallasjonLukk}
                                            >
                                                <CloseIcon className='jvbtnicon' />
                                                {Text({ tid: "avbryt" })}
                                            </Button>
                                        </DialogActions>
                                    </div>
                                }

                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={ModalUtvidRettefrist}
                            onClose={ModalUtvidRettefristLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "sokutvidrettefrist" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalUtvidRettefristLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box component="form" onSubmit={SendUtvidRettefrist}>
                                <DialogContent>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ mb: 2 }}>
                                            <TextField
                                                type="text"
                                                margin="normal"
                                                id="saksnummer"
                                                label={Text({ tid: "saksnummer" })}
                                                name="saksnummer"
                                                defaultValue={AktivRettefristSaksnummer}
                                                disabled
                                                sx={{ pr: 2 }}
                                            />
                                            <TextField
                                                type="text"
                                                margin="normal"
                                                id="meldingid"
                                                label={Text({ tid: "meldingsid" })}
                                                name="meldingsid"
                                                defaultValue={AktivRettefrist}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sx={{ mb: 2 }}>
                                        <TextField
                                            type="text"
                                            margin="normal"
                                            id="gammeldato"
                                            label={Text({ tid: "rettefristdato" })}
                                            name="gammeldato"
                                            defaultValue={AktivRettefristDato}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6} sx={{ mb: 2 }}>
                                        <TextField
                                            type="date"
                                            margin="normal"
                                            id="nydato"
                                            label={Text({ tid: "rettefristdatoutsettelse" })}
                                            name="nydato"
                                            defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            multiline
                                            rows="5"
                                            margin="normal"
                                            fullWidth
                                            id="kommentar"
                                            label={Text({ tid: "aarsakforsoknad" })}
                                            name="kommentar"
                                        />
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendsoknad" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={ModalUtvidRettefristLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={ModalEndreAktor}
                            onClose={ModalEndreAktorLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "oppdateraktoropplysninger" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalEndreAktorLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                    {Text({ tid: "aktoroppdateringpersonvern" })}
                                </Alert>
                                <Box component="form" id="kontaktoppdateringform" onSubmit={sendAktorOppdaterKontaktinfo}>
                                    <Grid container>
                                        <Grid item xs={12} p={2}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="aktorepost"
                                                label={Text({ tid: "epost" })}
                                                name="epost"
                                                defaultValue={aktor[0]?.Epost}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} p={2}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="aktormobil"
                                                label={Text({ tid: "mobiltelefon" })}
                                                name="mobil"
                                                defaultValue={aktor[0]?.Mobilnummer}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        form="kontaktoppdateringform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "oppdater" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={ModalEndreAktorLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={ModalUtmontering}
                            onClose={ModalUtmonteringLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "meldingomutmontering" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalUtmonteringLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box component="form" id="utmonteringform" onSubmit={UtmonteringFormik.handleSubmit}>
                                <DialogContent>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            type="date"
                                            margin="normal"
                                            fullWidth
                                            id="utmontertdato"
                                            label={Text({ tid: "datoutmontert" })}
                                            name="utmontertdato"
                                            defaultValue={UtmonteringFormik.values.utmontertdato}
                                            onChange={UtmonteringFormik.handleChange}
                                            onBlur={UtmonteringFormik.handleBlur}
                                            error={UtmonteringFormik.touched.utmontertdato && Boolean(UtmonteringFormik.errors.utmontertdato)}
                                            helperText={UtmonteringFormik.touched.utmontertdato && UtmonteringFormik.errors.utmontertdato}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        {(aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Aktivtloyve === 1) ?
                                            <TextField
                                                select
                                                fullWidth
                                                maxRows={10}
                                                label={Text({ tid: "aarsak" })}
                                                name="loyvestatus"
                                                id="loyvestatus"
                                                defaultValue={UtmonteringFormik.values.loyvestatus}
                                                onChange={UtmonteringFormik.handleChange}
                                                onBlur={UtmonteringFormik.handleBlur}
                                                error={UtmonteringFormik.touched.loyvestatus && Boolean(UtmonteringFormik.errors.loyvestatus)}
                                                helperText={UtmonteringFormik.touched.loyvestatus && UtmonteringFormik.errors.loyvestatus}
                                                required
                                            >
                                                <MenuItem key="um1" value="1">
                                                    Løyvet skal innleveres
                                                </MenuItem>
                                                <MenuItem key="um2" value="2">
                                                    Løyvet skal beholdes
                                                </MenuItem>
                                                <MenuItem key="um3" value="3">
                                                    Taksameter og kjøretøy er overtatt av ny løyvehaver
                                                </MenuItem>
                                                <MenuItem key="um4" value="4">
                                                    Taksameter og kjøretøy har fått nytt løyvenummer hos samme løyvehaver
                                                </MenuItem>
                                                <MenuItem key="um5" value="5">
                                                    Nytt taksameter
                                                </MenuItem>
                                                <MenuItem key="um6" value="6">
                                                    Nytt kjøretøy
                                                </MenuItem>
                                                <MenuItem key="um7" value="7">
                                                    Endring av foretaks organisasjonsnummer
                                                </MenuItem>
                                            </TextField>
                                            :
                                            <TextField
                                                select
                                                fullWidth
                                                maxRows={10}
                                                label={Text({ tid: "aarsak" })}
                                                name="loyvestatus"
                                                id="loyvestatus"
                                                defaultValue={8}
                                                disabled
                                            >
                                                <MenuItem value="8">
                                                    Løyvet er ugyldig/flyttet til annen eier
                                                </MenuItem>
                                            </TextField>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 2 }}>
                                        <TextField
                                            multiline
                                            rows="5"
                                            margin="normal"
                                            fullWidth
                                            id="kommentar"
                                            label={Text({ tid: "utmonteringkommentar" })}
                                            name="kommentar"
                                            defaultValue={UtmonteringFormik.values.kommentar}
                                            onChange={UtmonteringFormik.handleChange}
                                            onBlur={UtmonteringFormik.handleBlur}
                                            error={UtmonteringFormik.touched.kommentar && Boolean(UtmonteringFormik.errors.kommentar)}
                                            helperText={UtmonteringFormik.touched.kommentar && UtmonteringFormik.errors.kommentar}
                                        />
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendmelding" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={ModalUtmonteringLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={ModalHistorikk}
                            onClose={ModalHistorikkLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        Historikk
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalHistorikkLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Table>
                                    <TableHead>
                                        <TableCell>Dato</TableCell>
                                        <TableCell>Referanse</TableCell>
                                        <TableCell>Innsender</TableCell>
                                        <TableCell>Type</TableCell>
                                    </TableHead>
                                    {aktor[0]?.instrumenter[AktivtInstrumentIndex]?.meldinger && aktor[0]?.instrumenter[AktivtInstrumentIndex]?.meldinger.map(({ MeldingId, MeldingType, Navn, OpprettetDato }: any, indexhist: number) => {
                                        return <TableRow key={"indexhist" + indexhist} sx={{ mb: 1 }}>
                                            <TableCell className='jvtablerow'>{OpprettetDato}</TableCell>
                                            <TableCell className='jvtablerow'>{MeldingId}</TableCell>
                                            <TableCell className='jvtablerow'>{Navn}</TableCell>
                                            <TableCell className='jvtablerow'>{MeldingType}</TableCell>
                                        </TableRow>
                                    })
                                    }
                                </Table>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={ModalPlombeBrudd}
                            onClose={ModalPlombebruddLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "utfortreparasjonplombebruddutenpaalegg" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalPlombebruddLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Box component="form" id="plombebruddform" onSubmit={PlombebruddFormik.handleSubmit}>
                                    <Grid item xs={12} sx={{ mb: 1 }}>
                                        <TextField
                                            type="date"
                                            margin="normal"
                                            fullWidth
                                            id="plombebrudddato"
                                            label={Text({ tid: "instrepdato" })}
                                            name="plombebrudddato"
                                            defaultValue={PlombebruddFormik.values.plombebrudddato}
                                            onChange={PlombebruddFormik.handleChange}
                                            onBlur={PlombebruddFormik.handleBlur}
                                            error={PlombebruddFormik.touched.plombebrudddato && Boolean(PlombebruddFormik.errors.plombebrudddato)}
                                            helperText={PlombebruddFormik.touched.plombebrudddato && PlombebruddFormik.errors.plombebrudddato}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            fullWidth
                                            maxRows={10}
                                            label={Text({ tid: "aarsak" })}
                                            name="plombebruddaarsak"
                                            id="plombebruddaarsak"
                                            defaultValue={"1"}
                                            onChange={(e) => handlePlombebruddAarsak(e)}
                                            onBlur={PlombebruddFormik.handleBlur}
                                            error={PlombebruddFormik.touched.plombebruddaarsak && Boolean(PlombebruddFormik.errors.plombebruddaarsak)}
                                            helperText={PlombebruddFormik.touched.plombebruddaarsak && PlombebruddFormik.errors.plombebruddaarsak}
                                            required
                                        >
                                            <MenuItem value="1">
                                                Brudd på elektronisk plombe
                                            </MenuItem>
                                            <MenuItem value="2">
                                                Bytte av drosjesentral
                                            </MenuItem>
                                            <MenuItem value="3">
                                                Endring av k-faktor/dekkdimensjon
                                            </MenuItem>
                                            <MenuItem value="4">
                                                Forsegling taksameter
                                            </MenuItem>
                                            <MenuItem value="5">
                                                Forseglingsplate på taksameter
                                            </MenuItem>
                                            <MenuItem value="6">
                                                Ny plombe på display DI 80X på TP Radio Au2Tax
                                            </MenuItem>
                                            <MenuItem value="7">
                                                Ny plombe på koblingsboks
                                            </MenuItem>
                                            <MenuItem value="8">
                                                Ny programvareversjon
                                            </MenuItem>
                                            <MenuItem value="9">
                                                Plombebrudd for deksel til programmeringsbryter
                                            </MenuItem>
                                            <MenuItem value="10">
                                                Plombebrudd som følge av service/reparasjon
                                            </MenuItem>
                                            <MenuItem value="11">
                                                Tilkobling til betalingsenhet
                                            </MenuItem>
                                            <MenuItem value="12">
                                                Nytt kjennemerke
                                            </MenuItem>
                                        </TextField>
                                    </Grid>
                                    {pbvisdekk &&
                                        <div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="pbdekk"
                                                    label={Text({ tid: "dekkdim" })}
                                                    name="pbdekk"
                                                    defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex].Dekkdimensjon}
                                                    // defaultValue={PlombebruddFormik.values.pbdekk}
                                                    onChange={PlombebruddFormik.handleChange}
                                                    onBlur={PlombebruddFormik.handleBlur}
                                                    error={PlombebruddFormik.touched.pbdekk && Boolean(PlombebruddFormik.errors.pbdekk)}
                                                    helperText={PlombebruddFormik.touched.pbdekk && PlombebruddFormik.errors.pbdekk}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="pbkfaktor"
                                                    label={Text({ tid: "kfaktor" })}
                                                    name="pbkfaktor"
                                                    defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex].KFaktor}
                                                    onChange={PlombebruddFormik.handleChange}
                                                    onBlur={PlombebruddFormik.handleBlur}
                                                    error={PlombebruddFormik.touched.pbkfaktor && Boolean(PlombebruddFormik.errors.pbkfaktor)}
                                                    helperText={PlombebruddFormik.touched.pbkfaktor && PlombebruddFormik.errors.pbkfaktor}
                                                    required
                                                />
                                            </Grid>
                                        </div>
                                    }
                                    {pbvisswver &&
                                        <div>
                                            <Grid item xs={12}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="pbswversjon"
                                                    label={Text({ tid: "softwareversjon" })}
                                                    name="pbswversjon"
                                                    defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex].SoftwareVersion}
                                                    onChange={PlombebruddFormik.handleChange}
                                                    onBlur={PlombebruddFormik.handleBlur}
                                                    error={PlombebruddFormik.touched.pbswversjon && Boolean(PlombebruddFormik.errors.pbswversjon)}
                                                    helperText={PlombebruddFormik.touched.pbswversjon && PlombebruddFormik.errors.pbswversjon}
                                                    required
                                                />
                                            </Grid>
                                        </div>
                                    }
                                    {pbvissentral &&
                                        <div>
                                            <Grid container sx={{ mt: 2 }}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        size="small"
                                                        type="number"
                                                        fullWidth
                                                        id="drosjesentralorg"
                                                        label={Text({ tid: "drosjesentralorg" })}
                                                        name="drosjesentralorg"
                                                        onChange={(e) => handleDrosjesentralSokPlombebrudd(e)}
                                                        sx={{ pr: 2 }}
                                                        defaultValue={PlombebruddFormik.values.drosjesentralorg}
                                                        onBlur={PlombebruddFormik.handleBlur}
                                                        error={PlombebruddFormik.touched.drosjesentralorg && Boolean(PlombebruddFormik.errors.drosjesentralorg)}
                                                        helperText={PlombebruddFormik.touched.drosjesentralorg && PlombebruddFormik.errors.drosjesentralorg}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        id="drosjesentralnavn"
                                                        label={Text({ tid: "drosjesentralnavn" })}
                                                        name="drosjesentralnavn"
                                                        value={BrregRespons[0]?.navn}
                                                        defaultValue=" "
                                                        sx={{ pr: 2 }}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                    {pbvisregnr &&
                                        <div>
                                            <Grid item xs={12} pt={2}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="pbregnr"
                                                    label={Text({ tid: "nyttkjennemerke" })}
                                                    name="pbregnr"
                                                    defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex].Regnr}
                                                    onChange={PlombebruddFormik.handleChange}
                                                    onBlur={PlombebruddFormik.handleBlur}
                                                    error={PlombebruddFormik.touched.pbregnr && Boolean(PlombebruddFormik.errors.pbregnr)}
                                                    helperText={PlombebruddFormik.touched.pbregnr && PlombebruddFormik.errors.pbregnr}
                                                />
                                            </Grid>
                                        </div>
                                    }
                                    <Grid item xs={12} sx={{ mb: 1 }}>
                                        <TextField
                                            multiline
                                            rows="2"
                                            margin="normal"
                                            fullWidth
                                            id="kommentar"
                                            label={Text({ tid: "kommentarvalgfritt" })}
                                            name="kommentar"
                                            defaultValue={PlombebruddFormik.values.kommentar}
                                            onChange={PlombebruddFormik.handleChange}
                                            onBlur={PlombebruddFormik.handleBlur}
                                            error={PlombebruddFormik.touched.kommentar && Boolean(PlombebruddFormik.errors.kommentar)}
                                            helperText={PlombebruddFormik.touched.kommentar && PlombebruddFormik.errors.kommentar}
                                        />
                                    </Grid>
                                </Box>
                                <Typography>
                                    {Text({ tid: "plomber" })}
                                </Typography>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                <TableCell className='jvtablehead'>{Text({ tid: "valg" })}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {ModalAktivePlomber && ModalAktivePlomber.map(({ PlomberingId, Plassering, Plombenavn, Plombetype, Plombenummer, PlombeSkalSlettes }: any, indexpl: number) => {
                                                return <TableRow key={"plrow" + PlomberingId} sx={{ mb: 1 }} className={PlombeSkalSlettes ? "tablewiperow" : ""}>
                                                    <TableCell className='jvtablerow'>{Plombenavn}</TableCell>
                                                    <TableCell className='jvtablerow'>{Plombetype}</TableCell>
                                                    <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                    <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                    <TableCell className='jvtablerow'>
                                                        <FormControlLabel
                                                            key={"plid_" + PlomberingId}
                                                            value={PlomberingId}
                                                            control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} checkedIcon={<DeleteForeverIcon sx={{ color: '#FF5733' }} />} />}
                                                            label=""
                                                            onChange={handleCheckSlettPlombe}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            })
                                            }
                                            {NyePlomber && NyePlomber.map(({ nyplnavn, nyplnummer, nypltype, nyplplassering }: any, indexnypl: number) => {
                                                return <TableRow key={indexnypl} sx={{ mb: 1 }}>
                                                    <TableCell className='jvtablerow'>
                                                        {nyplnavn}
                                                    </TableCell>
                                                    <TableCell className='jvtablerow'>{nypltype}</TableCell>
                                                    <TableCell className='jvtablerow'>{nyplnummer}</TableCell>
                                                    <TableCell className='jvtablerow'>{nyplplassering}</TableCell>
                                                    <TableCell className='jvtablerow'>
                                                        <FormControlLabel
                                                            key={"nyplid_" + indexnypl}
                                                            value={indexnypl}
                                                            control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} />}
                                                            checked={false}
                                                            label=""
                                                            onChange={handleCheckSlettNyPlombe}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            })
                                            }

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography>
                                    {Text({ tid: "leggtilnyplombe" })}
                                </Typography>
                                <Box component="form" id="plombebruddform" onSubmit={LeggtilNyPlombe}>
                                    <Grid container>
                                        <Grid item xs={2} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label="Plombenavn"
                                                name="nyplnavn"
                                                id="nyplnavn"
                                                defaultValue={"1"}
                                                ref={nyPlombeNavnRef}
                                                required
                                            >
                                                <MenuItem value="1">
                                                    {Text({ tid: "taksameter" })}
                                                </MenuItem>
                                                <MenuItem value="2">
                                                    {Text({ tid: "spenning" })}
                                                </MenuItem>
                                                <MenuItem value="3">
                                                    {Text({ tid: "koblingsboks" })}
                                                </MenuItem>
                                                <MenuItem value="4">
                                                    {Text({ tid: "display" })}
                                                </MenuItem>
                                                <MenuItem value="5">
                                                    {Text({ tid: "kalibreringskode" })}
                                                </MenuItem>
                                                <MenuItem value="6">
                                                    {Text({ tid: "indikator" })}
                                                </MenuItem>
                                                <MenuItem value="7">
                                                    {Text({ tid: "veiecelle" })}
                                                </MenuItem>
                                                <MenuItem value="8">
                                                    {Text({ tid: "annet" })}
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={2} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label="Type"
                                                name="nypltype"
                                                id="nypltype"
                                                defaultValue={"2"}
                                                ref={nyPlombeTypeRef}
                                                required
                                            >
                                                <MenuItem value="1" aria-label='Elektronisk' aria-labelledby='Elektronisk'>
                                                    {Text({ tid: "elektronisk" })}
                                                </MenuItem>
                                                <MenuItem value="2" aria-label='Fysisk' aria-labelledby='Fysisk'>
                                                    {Text({ tid: "fysisk" })}
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={3} sx={{ mb: 1, pr: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="nyplnummer"
                                                label={Text({ tid: "plombenummer" })}
                                                name="nyplnummer"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={3} sx={{ mb: 1 }}>
                                            <TextField
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                                id="nyplplassering"
                                                label={Text({ tid: "plassering" })}
                                                name="nyplplassering"
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={1} sx={{ ml: 1, pt: 2 }}>
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                color="primary"
                                            >
                                                <AddCircleIcon className='jvbtnicon' />
                                                {Text({ tid: "leggtil" })}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size="small"
                                    type="submit"
                                    form="plombebruddform"
                                    variant="outlined"
                                    color="success"
                                >
                                    <SaveIcon className='jvbtnicon' />
                                    {Text({ tid: "sendmelding" })}
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={ModalPlombebruddLukk}
                                >
                                    <CloseIcon className='jvbtnicon' />
                                    {Text({ tid: "avbryt" })}
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={ModalRettefrist}
                            onClose={ModalRettefristLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        <Text tid="utfortrettefrist" />
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalRettefristLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Box component="form" id="rettefristform" onSubmit={RettefristFormik.handleSubmit}>
                                    <Card color="info" sx={{ mb: 2 }}>
                                        <Alert severity="warning">
                                            <Text tid="rettefristsaksnummer" />: {AktivRettefristSaksnummer}
                                        </Alert>
                                        <CardContent>
                                            <Box sx={{ p: 1, mr: 2 }}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                    <Text tid="saksnummer" />: <b>#{AktivRettefristSaksnummer}</b> | <Text tid="meldingsid" /> <b>{AktivRettefrist}</b> | <Text tid="frist" />: <b>{AktivRettefristDato}</b>
                                                </Typography>
                                            </Box>
                                            <input type="hidden" id="rettefristid" name="rettefristid" value={AktivRettefrist} />
                                            <input type="hidden" id="saksnummer" name="saksnummer" value={AktivRettefristSaksnummer} />
                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    maxRows={10}
                                                    label={Text({ tid: "rettingutfort" })}
                                                    name="rettefristaarsak"
                                                    id="rettefristaarsak"
                                                    onChange={handleRettefristAarsak}
                                                    defaultValue={RettefristFormik.values.rettefristaarsak}
                                                    onBlur={RettefristFormik.handleBlur}
                                                    error={RettefristFormik.touched.rettefristaarsak && Boolean(RettefristFormik.errors.rettefristaarsak)}
                                                    helperText={RettefristFormik.touched.rettefristaarsak && RettefristFormik.errors.rettefristaarsak}
                                                >
                                                    <MenuItem value="1">
                                                        Melder installasjon av taksameter
                                                    </MenuItem>
                                                    <MenuItem value="2">
                                                        Taksameter utmontert
                                                    </MenuItem>
                                                    <MenuItem value="3">
                                                        Endring av k-faktor/dekkdimensjon
                                                    </MenuItem>
                                                    <MenuItem value="4">
                                                        Ny programvareversjon
                                                    </MenuItem>
                                                    <MenuItem value="5">
                                                        Plombert spenningstilførsel
                                                    </MenuItem>
                                                    <MenuItem value="6">
                                                        Plombert display
                                                    </MenuItem>
                                                    <MenuItem value="7">
                                                        Plombert koblingsboks
                                                    </MenuItem>
                                                    <MenuItem value="8">
                                                        Plombert elektronisk plombe
                                                    </MenuItem>
                                                    <MenuItem value="9">
                                                        Tilkoblet betalingsterminal (EMV)
                                                    </MenuItem>
                                                    <MenuItem value="10">
                                                        Tilkoblet andre enheter til taksameteret
                                                    </MenuItem>
                                                    <MenuItem value="11">
                                                        Ny deksel på display
                                                    </MenuItem>
                                                    <MenuItem value="12">
                                                        Reparasjon
                                                    </MenuItem>
                                                    <MenuItem value="13">
                                                        Tilkoblet kabel til taksameter
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                            {VisObservasjoner &&
                                                <div>
                                                    <Typography sx={{ mt: 1 }}>
                                                        {Text({ tid: "observasjonermaarettes" })}
                                                    </Typography>
                                                    <FormGroup>
                                                        {aktor[0]?.instrumenter[AktivtInstrumentIndex]?.rettefristobservasjoner && aktor[0]?.instrumenter[AktivtInstrumentIndex].rettefristobservasjoner.map(({ ObservasjonId, Kode, Beskrivelse }: any, indexobs: number) => {
                                                            return <FormControlLabel control={<Checkbox required />} label={Beskrivelse} />
                                                        })
                                                        }
                                                    </FormGroup>
                                                </div>
                                            }
                                            {visUtmontering &&
                                                <Grid item xs={12} sx={{ mb: 2, mt: 2 }}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        maxRows={10}
                                                        label={Text({ tid: "aarsak" })}
                                                        name="loyvestatus"
                                                        id="loyvestatus"
                                                        defaultValue={RettefristFormik.values.loyvestatus}
                                                        onChange={RettefristFormik.handleChange}
                                                        onBlur={RettefristFormik.handleBlur}
                                                        error={RettefristFormik.touched.loyvestatus && Boolean(RettefristFormik.errors.loyvestatus)}
                                                        helperText={RettefristFormik.touched.loyvestatus && RettefristFormik.errors.loyvestatus}
                                                    >
                                                        <MenuItem key="um1" value="1">
                                                            Løyvet skal innleveres
                                                        </MenuItem>
                                                        <MenuItem key="um2" value="2">
                                                            Løyvet skal beholdes
                                                        </MenuItem>
                                                        <MenuItem key="um3" value="3">
                                                            Taksameter og kjøretøy er overtatt av ny løyvehaver
                                                        </MenuItem>
                                                        <MenuItem key="um4" value="4">
                                                            Taksameter og kjøretøy har fått nytt løyvenummer hos samme løyvehaver
                                                        </MenuItem>
                                                        <MenuItem key="um5" value="5">
                                                            Nytt taksameter
                                                        </MenuItem>
                                                        <MenuItem key="um6" value="6">
                                                            Nytt kjøretøy
                                                        </MenuItem>
                                                        <MenuItem key="um7" value="7">
                                                            Endring av foretaks organisasjonsnummer
                                                        </MenuItem>
                                                        <MenuItem key="um8" value="8">
                                                            Løyvet er ugyldig/flyttet til annen eier
                                                        </MenuItem>
                                                    </TextField>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <TextField
                                                    multiline
                                                    rows="3"
                                                    margin="normal"
                                                    fullWidth
                                                    id="rettingbeskrivelse"
                                                    label={Text({ tid: "rettingbeskrivelse" })}
                                                    name="rettingbeskrivelse"
                                                    defaultValue={RettefristFormik.values.rettingbeskrivelse}
                                                    onChange={RettefristFormik.handleChange}
                                                    onBlur={RettefristFormik.handleBlur}
                                                    error={RettefristFormik.touched.rettingbeskrivelse && Boolean(RettefristFormik.errors.rettingbeskrivelse)}
                                                    helperText={RettefristFormik.touched.rettingbeskrivelse && RettefristFormik.errors.rettingbeskrivelse}
                                                />
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="date"
                                                id="dato"
                                                label={Text({ tid: "rettingdato" })}
                                                name="dato"
                                                sx={{ pr: 2 }}
                                                defaultValue={RettefristFormik.values.rettingdato}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.rettingdato && Boolean(RettefristFormik.errors.rettingdato)}
                                                helperText={RettefristFormik.touched.rettingdato && RettefristFormik.errors.rettingdato}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="loyvenummer"
                                                label={Text({ tid: "loyvenummer" })}
                                                name="loyvenummer"
                                                sx={{ pr: 2 }}
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Loyvenummer}
                                                disabled
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.loyvenummer && Boolean(RettefristFormik.errors.loyvenummer)}
                                                helperText={RettefristFormik.touched.loyvenummer && RettefristFormik.errors.loyvenummer}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="taklampe"
                                                label={Text({ tid: "taklampe" })}
                                                name="taklampe"
                                                sx={{ pr: 2 }}
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Taklampenummer}
                                                disabled={rfdtaklampe}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.taklampe && Boolean(RettefristFormik.errors.taklampe)}
                                                helperText={RettefristFormik.touched.taklampe && RettefristFormik.errors.taklampe}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={4} sx={{ pr: 2 }}>
                                            <Autocomplete
                                                size="small"
                                                ListboxProps={{ style: { maxHeight: 150 } }}
                                                disablePortal
                                                id="kommune"
                                                onChange={(e, v) => VelgLokasjon(e, v)}
                                                options={kommuner}
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Drosjeomraade}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label={Text({ tid: "primaeromraade" })}
                                                    name="kommune"
                                                    required
                                                />}
                                                disabled={rfdomraade}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                type="number"
                                                fullWidth
                                                id="drosjesentralorg"
                                                label={Text({ tid: "drosjesentralorg" })}
                                                name="drosjesentralorg"
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.DrosjesentralOrgNummer}
                                                onChange={(e) => handleDrosjesentralSok(e)}
                                                sx={{ pr: 2 }}
                                                disabled={rfddrosjeorg}
                                                // onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.drosjesentralorg && Boolean(RettefristFormik.errors.drosjesentralorg)}
                                                helperText={RettefristFormik.touched.drosjesentralorg && RettefristFormik.errors.drosjesentralorg}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="drosjesentralnavn"
                                                label={Text({ tid: "drosjesentralnavn" })}
                                                name="drosjesentralnavn"
                                                value={BrregRespons[0]?.navn}
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.DrosjesentralAktor}
                                                sx={{ pr: 2 }}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={3}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="bilmerke"
                                                label={Text({ tid: "bilmerke" })}
                                                name="bilmerke"
                                                sx={{ pr: 2 }}
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Bilmerke}
                                                disabled={rfdbilmerke}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.bilmerke && Boolean(RettefristFormik.errors.bilmerke)}
                                                helperText={RettefristFormik.touched.bilmerke && RettefristFormik.errors.bilmerke}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size="small"
                                                type="number"
                                                fullWidth
                                                id="aarsmodell"
                                                label={Text({ tid: "aarsmodell" })}
                                                name="aarsmodell"
                                                sx={{ pr: 2 }}
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Arsmodel}
                                                disabled={rfdbilaarsmod}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.aarsmodell && Boolean(RettefristFormik.errors.aarsmodell)}
                                                helperText={RettefristFormik.touched.aarsmodell && RettefristFormik.errors.aarsmodell}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="regnr"
                                                label={Text({ tid: "regnr" })}
                                                name="regnr"
                                                sx={{ pr: 2 }}
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Regnr}
                                                disabled={rfdregnr}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.regnr && Boolean(RettefristFormik.errors.regnr)}
                                                helperText={RettefristFormik.touched.regnr && RettefristFormik.errors.regnr}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="dekkdim"
                                                label={Text({ tid: "dekkdim" })}
                                                name="dekkdim"
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Dekkdimensjon}
                                                sx={{ pr: 2 }}
                                                disabled={rfddekkdim}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.dekkdim && Boolean(RettefristFormik.errors.dekkdim)}
                                                helperText={RettefristFormik.touched.dekkdim && RettefristFormik.errors.dekkdim}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={4} sx={{ pr: 2 }}>
                                            <Autocomplete
                                                size="small"
                                                ListboxProps={{ style: { maxHeight: 150 } }}
                                                disablePortal
                                                id="fabrikat"
                                                onChange={(e, v) => VelgTaksameter(e, v)}
                                                options={TaksameterFabrikater}
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Fabrikat}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label={Text({ tid: "taksameterfabrikat" })}
                                                    name="fabrikat"

                                                />}
                                                disabled={rfdtaksameterfabrikat}
                                            />
                                        </Grid>
                                        <Grid item xs={4} pr={2}>
                                            <Autocomplete
                                                size="small"
                                                ListboxProps={{ style: { maxHeight: 150 } }}
                                                disablePortal
                                                id="modell"
                                                onChange={(e, v) => VelgModell(e, v)}
                                                options={TaksameterModeller}
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Modell}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label={Text({ tid: "taksametermodell" })}
                                                    name="modell"
                                                    required
                                                />}
                                                disabled={rfdtaksametermodell}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="serienr"
                                                label={Text({ tid: "serienr" })}
                                                name="serienr"
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Serienummer}
                                                sx={{ pr: 2 }}
                                                disabled={rfdserienummer}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.serienr && Boolean(RettefristFormik.errors.serienr)}
                                                helperText={RettefristFormik.touched.serienr && RettefristFormik.errors.serienr}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="swver"
                                                label={Text({ tid: "softwareversjon" })}
                                                name="swver"
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.SoftwareVersion}
                                                sx={{ pr: 2 }}
                                                disabled={rfdsoftwareversjon}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.swver && Boolean(RettefristFormik.errors.swver)}
                                                helperText={RettefristFormik.touched.swver && RettefristFormik.errors.swver}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="kfaktor"
                                                label={Text({ tid: "kfaktor" })}
                                                name="kfaktor"
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.KFaktor}
                                                sx={{ pr: 2 }}
                                                disabled={rfdkfaktor}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.kfaktor && Boolean(RettefristFormik.errors.kfaktor)}
                                                helperText={RettefristFormik.touched.kfaktor && RettefristFormik.errors.kfaktor}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="jvstrekkode"
                                                label={Text({ tid: "jvstrekkode" })}
                                                name="jvstrekkode"
                                                defaultValue={aktor[0]?.instrumenter[AktivtInstrumentIndex]?.Strekkode}
                                                sx={{ pr: 2 }}
                                                disabled={rfdjvstrekkode}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.jvstrekkode && Boolean(RettefristFormik.errors.jvstrekkode)}
                                                helperText={RettefristFormik.touched.jvstrekkode && RettefristFormik.errors.jvstrekkode}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                rows="2"
                                                size="small"
                                                fullWidth
                                                id="ekstraopplysninger"
                                                label={Text({ tid: "kommentarer" })}
                                                name="ekstraopplysninger"
                                                sx={{ pr: 2 }}
                                                onChange={RettefristFormik.handleChange}
                                                onBlur={RettefristFormik.handleBlur}
                                                error={RettefristFormik.touched.ekstraopplysninger && Boolean(RettefristFormik.errors.ekstraopplysninger)}
                                                helperText={RettefristFormik.touched.ekstraopplysninger && RettefristFormik.errors.ekstraopplysninger}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                {VisPlomber &&
                                    <Card color="info">
                                        <Alert severity="info">
                                            <Text tid="plomber" />
                                        </Alert>
                                        <CardContent>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "valg" })}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {ModalAktivePlomber && ModalAktivePlomber.map(({ PlomberingId, Plassering, Plombenavn, Plombetype, Plombenummer, PlombeSkalSlettes }: any, indexpl: number) => {
                                                            return <TableRow key={"plrow" + PlomberingId} sx={{ mb: 1 }} className={PlombeSkalSlettes ? "tablewiperow" : ""}>
                                                                <TableCell className='jvtablerow'>{Plombenavn}</TableCell>
                                                                <TableCell className='jvtablerow'>{Plombetype}</TableCell>
                                                                <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                                <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                                <TableCell className='jvtablerow'>
                                                                    <FormControlLabel
                                                                        key={"plid_" + PlomberingId}
                                                                        value={PlomberingId}
                                                                        control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} checkedIcon={<DeleteForeverIcon sx={{ color: '#FF5733' }} />} />}
                                                                        label=""
                                                                        onChange={handleCheckSlettPlombe}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                        }
                                                        {NyePlomber && NyePlomber.map(({ nyplnavn, nyplnummer, nypltype, nyplplassering }: any, indexnypl: number) => {
                                                            return <TableRow key={indexnypl} sx={{ mb: 1 }}>
                                                                <TableCell className='jvtablerow'>
                                                                    {nyplnavn}
                                                                </TableCell>
                                                                <TableCell className='jvtablerow'>{nypltype}</TableCell>
                                                                <TableCell className='jvtablerow'>{nyplnummer}</TableCell>
                                                                <TableCell className='jvtablerow'>{nyplplassering}</TableCell>
                                                                <TableCell className='jvtablerow'>
                                                                    <FormControlLabel
                                                                        key={"nyplid_" + indexnypl}
                                                                        value={indexnypl}
                                                                        control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} />}
                                                                        checked={false}
                                                                        label=""
                                                                        onChange={handleCheckSlettNyPlombe}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                        }

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Card color="info" sx={{ mt: 5 }}>
                                                <CardContent>
                                                    <Typography>
                                                        {Text({ tid: "leggtilnyplombe" })}
                                                    </Typography>
                                                    <Box component="form" id="plombebruddform" onSubmit={LeggtilNyPlombe}>
                                                        <Grid container>
                                                            <Grid item xs={2} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                                <TextField
                                                                    size="small"
                                                                    select
                                                                    fullWidth
                                                                    label="Plombenavn"
                                                                    name="nyplnavn"
                                                                    id="nyplnavn"
                                                                    defaultValue={"1"}
                                                                    ref={nyPlombeNavnRef}
                                                                    required
                                                                >
                                                                    <MenuItem value="1">
                                                                        {Text({ tid: "taksameter" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="2">
                                                                        {Text({ tid: "spenning" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="3">
                                                                        {Text({ tid: "koblingsboks" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="4">
                                                                        {Text({ tid: "display" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="5">
                                                                        {Text({ tid: "kalibreringskode" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="6">
                                                                        {Text({ tid: "indikator" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="7">
                                                                        {Text({ tid: "veiecelle" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="8">
                                                                        {Text({ tid: "annet" })}
                                                                    </MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={2} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                                <TextField
                                                                    size="small"
                                                                    select
                                                                    fullWidth
                                                                    label="Type"
                                                                    name="nypltype"
                                                                    id="nypltype"
                                                                    defaultValue={"2"}
                                                                    ref={nyPlombeTypeRef}
                                                                    required
                                                                >
                                                                    <MenuItem value="1" aria-label='Elektronisk' aria-labelledby='Elektronisk'>
                                                                        {Text({ tid: "elektronisk" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="2" aria-label='Fysisk' aria-labelledby='Fysisk'>
                                                                        {Text({ tid: "fysisk" })}
                                                                    </MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ mb: 1, pr: 1 }}>
                                                                <TextField
                                                                    size="small"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    id="nyplnummer"
                                                                    label={Text({ tid: "plombenummer" })}
                                                                    name="nyplnummer"
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ mb: 1 }}>
                                                                <TextField
                                                                    size="small"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    id="nyplplassering"
                                                                    label={Text({ tid: "plassering" })}
                                                                    name="nyplplassering"
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} sx={{ ml: 1, pt: 2 }}>
                                                                <Button
                                                                    type="submit"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                >
                                                                    <AddCircleIcon className='jvbtnicon' />
                                                                    {Text({ tid: "leggtil" })}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </CardContent>
                                    </Card>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size="small"
                                    type="submit"
                                    form="rettefristform"
                                    variant="outlined"
                                    color="success"
                                >
                                    <SaveIcon className='jvbtnicon' />
                                    {Text({ tid: "sendmelding" })}
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={ModalRettefristLukk}
                                >
                                    <CloseIcon className='jvbtnicon' />
                                    {Text({ tid: "avbryt" })}
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={ModalStansevedtak}
                            onClose={ModalStansevedtakLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        <Text tid="utfortstansevedtak" />
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalStansevedtakLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Box component="form" id="stansevedtakform" onSubmit={StansevedtakFormik.handleSubmit}>
                                    <Card color="info" sx={{ mb: 2 }}>
                                        <Alert severity="error">
                                            <Text tid="stansevedtaksaksnummer" />: {AktivRettefristSaksnummer}
                                        </Alert>
                                        <CardContent>
                                            <Box sx={{ p: 1, mr: 2 }}>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                    {Text({ tid: "saksnummer" })}: <b>#{AktivRettefristSaksnummer}</b> | {Text({ tid: "meldingsid" })} <b>#{AktivRettefrist}</b> | {Text({ tid: "frist" })} <b>{AktivRettefristDato}</b>
                                                </Typography>
                                            </Box>
                                            <input type="hidden" id="rettefristid" name="rettefristid" value={AktivRettefrist} />
                                            <input type="hidden" id="saksnummer" name="saksnummer" value={AktivRettefristSaksnummer} />
                                            <Grid item xs={12}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    maxRows={10}
                                                    label="Retting utført"
                                                    name="rettefristaarsak"
                                                    id="rettefristaarsak"
                                                    onChange={handleRettefristAarsak}
                                                    defaultValue={StansevedtakFormik.values.rettefristaarsak}
                                                    onBlur={StansevedtakFormik.handleBlur}
                                                    error={StansevedtakFormik.touched.rettefristaarsak && Boolean(StansevedtakFormik.errors.rettefristaarsak)}
                                                    helperText={StansevedtakFormik.touched.rettefristaarsak && StansevedtakFormik.errors.rettefristaarsak}
                                                >
                                                    <MenuItem value="1">
                                                        Melder installasjon av taksameter
                                                    </MenuItem>
                                                    <MenuItem value="2">
                                                        Taksameter utmontert
                                                    </MenuItem>
                                                    <MenuItem value="3">
                                                        Endring av k-faktor/dekkdimensjon
                                                    </MenuItem>
                                                    <MenuItem value="4">
                                                        Ny programvareversjon
                                                    </MenuItem>
                                                    <MenuItem value="5">
                                                        Plombert spenningstilførsel
                                                    </MenuItem>
                                                    <MenuItem value="6">
                                                        Plombert display
                                                    </MenuItem>
                                                    <MenuItem value="7">
                                                        Plombert koblingsboks
                                                    </MenuItem>
                                                    <MenuItem value="8">
                                                        Plombert elektronisk plombe
                                                    </MenuItem>
                                                    <MenuItem value="9">
                                                        Tilkoblet betalingsterminal (EMV)
                                                    </MenuItem>
                                                    <MenuItem value="10">
                                                        Tilkoblet andre enheter til taksameteret
                                                    </MenuItem>
                                                    <MenuItem value="11">
                                                        Ny deksel på display
                                                    </MenuItem>
                                                    <MenuItem value="12">
                                                        Reparasjon
                                                    </MenuItem>
                                                    <MenuItem value="13">
                                                        Tilkoblet kabel til taksameter
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                            {VisObservasjoner &&
                                                <div>
                                                    <Typography sx={{ mt: 1 }}>{Text({ tid: "observasjonermaarettes" })}</Typography>
                                                    <FormGroup>
                                                        {aktor[0]?.instrumenter[AktivtInstrumentIndex]?.rettefristobservasjoner && aktor[0]?.instrumenter[AktivtInstrumentIndex].rettefristobservasjoner.map(({ ObservasjonId, Kode, Beskrivelse }: any, indexobs: number) => {
                                                            return <FormControlLabel control={<Checkbox required />} label={Beskrivelse} />
                                                        })
                                                        }
                                                    </FormGroup>
                                                </div>
                                            }
                                            <Grid item xs={12}>
                                                <TextField
                                                    multiline
                                                    rows="3"
                                                    margin="normal"
                                                    fullWidth
                                                    id="rettingbeskrivelse"
                                                    label={Text({ tid: "rettingbeskrivelse" })}
                                                    name="rettingbeskrivelse"
                                                    onChange={StansevedtakFormik.handleChange}
                                                    onBlur={StansevedtakFormik.handleBlur}
                                                    error={StansevedtakFormik.touched.rettefristaarsak && Boolean(StansevedtakFormik.errors.rettefristaarsak)}
                                                    helperText={StansevedtakFormik.touched.rettefristaarsak && StansevedtakFormik.errors.rettefristaarsak}
                                                />
                                            </Grid>

                                        </CardContent>
                                    </Card>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="date"
                                                id="rettingdato"
                                                label={Text({ tid: "rettingdato" })}
                                                name="rettingdato"
                                                defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                                                sx={{ pr: 2 }}
                                                onChange={StansevedtakFormik.handleChange}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.rettingdato && Boolean(StansevedtakFormik.errors.rettingdato)}
                                                helperText={StansevedtakFormik.touched.rettingdato && StansevedtakFormik.errors.rettingdato}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="loyvenummer"
                                                label={Text({ tid: "loyvenummer" })}
                                                name="loyvenummer"
                                                sx={{ pr: 2 }}
                                                value={AktivtLoyvenummer}
                                                disabled
                                                onChange={StansevedtakFormik.handleChange}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.loyvenummer && Boolean(StansevedtakFormik.errors.loyvenummer)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="taklampe"
                                                label={Text({ tid: "taklampe" })}
                                                name="taklampe"
                                                sx={{ pr: 2 }}
                                                value={AktivtTaklampenummer}
                                                disabled
                                                onChange={StansevedtakFormik.handleChange}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.taklampe && Boolean(StansevedtakFormik.errors.taklampe)}
                                                helperText={StansevedtakFormik.touched.taklampe && StansevedtakFormik.errors.taklampe}

                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={4} sx={{ pr: 2 }}>
                                            <Autocomplete
                                                size="small"
                                                ListboxProps={{ style: { maxHeight: 150 } }}
                                                disablePortal
                                                id="kommune"
                                                onChange={(e, v) => VelgLokasjon(e, v)}
                                                options={kommuner}
                                                defaultValue={AktivtDrosjeomraade}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label={Text({ tid: "primaeromraade" })}
                                                    name="kommune"
                                                    required
                                                />}
                                                disabled={rfdomraade}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                type="number"
                                                fullWidth
                                                id="drosjesentralorg"
                                                label={Text({ tid: "drosjesentralorg" })}
                                                name="drosjesentralorg"
                                                value={AktivtDrosjesentralOrgNummer}
                                                onChange={(e) => handleChangeSVDrosjesentralOrgNummer(e)}
                                                sx={{ pr: 2 }}
                                                disabled={rfddrosjeorg}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.drosjesentralorg && Boolean(StansevedtakFormik.errors.drosjesentralorg)}
                                                helperText={StansevedtakFormik.touched.drosjesentralorg && StansevedtakFormik.errors.drosjesentralorg}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="drosjesentralnavn"
                                                label={Text({ tid: "drosjesentralnavn" })}
                                                name="drosjesentralnavn"
                                                value={AktivtDrosjesentralAktor}
                                                sx={{ pr: 2 }}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={3}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="bilmerke"
                                                label={Text({ tid: "bilmerke" })}
                                                name="bilmerke"
                                                sx={{ pr: 2 }}
                                                value={AktivtBilmerke}
                                                disabled={rfdbilmerke}
                                                onChange={handleChangeSVAktivtBilmerke}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.bilmerke && Boolean(StansevedtakFormik.errors.bilmerke)}
                                                helperText={StansevedtakFormik.touched.bilmerke && StansevedtakFormik.errors.bilmerke}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size="small"
                                                type="number"
                                                fullWidth
                                                id="aarsmodell"
                                                label={Text({ tid: "aarsmodell" })}
                                                name="aarsmodell"
                                                sx={{ pr: 2 }}
                                                value={AktivtAarsmodell}
                                                disabled={rfdbilaarsmod}
                                                onChange={handleChangeSVAktivtAarsmodell}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.aarsmodell && Boolean(StansevedtakFormik.errors.aarsmodell)}
                                                helperText={StansevedtakFormik.touched.aarsmodell && StansevedtakFormik.errors.aarsmodell}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="regnr"
                                                label={Text({ tid: "regnr" })}
                                                name="regnr"
                                                sx={{ pr: 2 }}
                                                value={AktivtRegnr}
                                                disabled={rfdregnr}
                                                onChange={handleChangeSVAktivtRegnr}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.regnr && Boolean(StansevedtakFormik.errors.regnr)}
                                                helperText={StansevedtakFormik.touched.regnr && StansevedtakFormik.errors.regnr}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="dekkdim"
                                                label={Text({ tid: "dekkdim" })}
                                                name="dekkdim"
                                                value={AktivtDekkdim}
                                                sx={{ pr: 2 }}
                                                disabled={rfddekkdim}
                                                onChange={handleChangeSVAktivtDekkdim}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.dekkdim && Boolean(StansevedtakFormik.errors.dekkdim)}
                                                helperText={StansevedtakFormik.touched.dekkdim && StansevedtakFormik.errors.dekkdim}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={4} sx={{ pr: 2 }}>
                                            <Autocomplete
                                                size="small"
                                                ListboxProps={{ style: { maxHeight: 150 } }}
                                                disablePortal
                                                id="fabrikat"
                                                onChange={(e, v) => VelgTaksameter(e, v)}
                                                options={TaksameterFabrikater}
                                                defaultValue={AktivtFabrikat}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label={Text({ tid: "taksameterfabrikat" })}
                                                    name="fabrikat"
                                                    required
                                                />}
                                                disabled={rfdtaksameterfabrikat}
                                            />
                                        </Grid>
                                        <Grid item xs={4} pr={2}>
                                            <Autocomplete
                                                size="small"
                                                ListboxProps={{ style: { maxHeight: 150 } }}
                                                disablePortal
                                                id="modell"
                                                onChange={(e, v) => VelgModell(e, v)}
                                                options={TaksameterModeller}
                                                defaultValue={AktivtModell}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label={Text({ tid: "taksametermodell" })}
                                                    name="modell"
                                                    required
                                                />}
                                                disabled={rfdtaksametermodell}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="serienr"
                                                label={Text({ tid: "serienr" })}
                                                name="serienr"
                                                value={AktivtSerienummer}
                                                sx={{ pr: 2 }}
                                                disabled={rfdserienummer}
                                                onChange={handleChangeSVAktivtSerienummer}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.serienr && Boolean(StansevedtakFormik.errors.serienr)}
                                                helperText={StansevedtakFormik.touched.serienr && StansevedtakFormik.errors.serienr}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="swver"
                                                label={Text({ tid: "softwareversjon" })}
                                                name="swver"
                                                value={AktivtSoftwareversjon}
                                                sx={{ pr: 2 }}
                                                disabled={rfdsoftwareversjon}
                                                onChange={handleChangeSVAktivtSoftwareversjon}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.swver && Boolean(StansevedtakFormik.errors.swver)}
                                                helperText={StansevedtakFormik.touched.swver && StansevedtakFormik.errors.swver}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="kfaktor"
                                                label={Text({ tid: "kfaktor" })}
                                                name="kfaktor"
                                                value={AktivtKFaktor}
                                                sx={{ pr: 2 }}
                                                disabled={rfdkfaktor}
                                                onChange={handleChangeSVAktivtKFaktor}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.kfaktor && Boolean(StansevedtakFormik.errors.kfaktor)}
                                                helperText={StansevedtakFormik.touched.kfaktor && StansevedtakFormik.errors.kfaktor}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="jvstrekkode"
                                                label={Text({ tid: "jvstrekkode" })}
                                                name="jvstrekkode"
                                                value={AktivtStrekkode}
                                                sx={{ pr: 2 }}
                                                disabled={rfdjvstrekkode}
                                                onChange={handleChangeSVAktivtStrekkode}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.jvstrekkode && Boolean(StansevedtakFormik.errors.jvstrekkode)}
                                                helperText={StansevedtakFormik.touched.jvstrekkode && StansevedtakFormik.errors.jvstrekkode}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                rows="2"
                                                size="small"
                                                fullWidth
                                                id="ekstraopplysninger"
                                                label={Text({ tid: "kommentarer" })}
                                                name="ekstraopplysninger"
                                                sx={{ pr: 2 }}
                                                onChange={StansevedtakFormik.handleChange}
                                                onBlur={StansevedtakFormik.handleBlur}
                                                error={StansevedtakFormik.touched.ekstraopplysninger && Boolean(StansevedtakFormik.errors.ekstraopplysninger)}
                                                helperText={StansevedtakFormik.touched.ekstraopplysninger && StansevedtakFormik.errors.ekstraopplysninger}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                {VisPlomber &&
                                    <Card color="info">
                                        <Alert severity="info">
                                            <Text tid="plomber" />
                                        </Alert>
                                        <CardContent>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                            <TableCell className='jvtablehead'>{Text({ tid: "valg" })}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {ModalAktivePlomber && ModalAktivePlomber.map(({ PlomberingId, Plassering, Plombenavn, Plombetype, Plombenummer, PlombeSkalSlettes }: any, indexpl: number) => {
                                                            return <TableRow key={"plrow" + PlomberingId} sx={{ mb: 1 }} className={PlombeSkalSlettes ? "tablewiperow" : ""}>
                                                                <TableCell className='jvtablerow'>{Plombenavn}</TableCell>
                                                                <TableCell className='jvtablerow'>{Plombetype}</TableCell>
                                                                <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                                <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                                <TableCell className='jvtablerow'>
                                                                    <FormControlLabel
                                                                        key={"plid_" + PlomberingId}
                                                                        value={PlomberingId}
                                                                        control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} checkedIcon={<DeleteForeverIcon sx={{ color: '#FF5733' }} />} />}
                                                                        label=""
                                                                        onChange={handleCheckSlettPlombe}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                        }
                                                        {NyePlomber && NyePlomber.map(({ nyplnavn, nyplnummer, nypltype, nyplplassering }: any, indexnypl: number) => {
                                                            return <TableRow key={indexnypl} sx={{ mb: 1 }}>
                                                                <TableCell className='jvtablerow'>
                                                                    {nyplnavn}
                                                                </TableCell>
                                                                <TableCell className='jvtablerow'>{nypltype}</TableCell>
                                                                <TableCell className='jvtablerow'>{nyplnummer}</TableCell>
                                                                <TableCell className='jvtablerow'>{nyplplassering}</TableCell>
                                                                <TableCell className='jvtablerow'>
                                                                    <FormControlLabel
                                                                        key={"nyplid_" + indexnypl}
                                                                        value={indexnypl}
                                                                        control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} />}
                                                                        checked={false}
                                                                        label=""
                                                                        onChange={handleCheckSlettNyPlombe}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                        }

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Card color="info" sx={{ mt: 5 }}>
                                                <CardContent>
                                                    <Typography>
                                                        {Text({ tid: "leggtilnyplombe" })}
                                                    </Typography>
                                                    <Box component="form" id="plombebruddform" onSubmit={LeggtilNyPlombe}>
                                                        <Grid container>
                                                            <Grid item xs={2} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                                <TextField
                                                                    size="small"
                                                                    select
                                                                    fullWidth
                                                                    label={Text({ tid: "plombenavn" })}
                                                                    name="nyplnavn"
                                                                    id="nyplnavn"
                                                                    defaultValue={"1"}
                                                                    ref={nyPlombeNavnRef}
                                                                    required
                                                                >
                                                                    <MenuItem value="1">
                                                                        {Text({ tid: "taksameter" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="2">
                                                                        {Text({ tid: "spenning" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="3">
                                                                        {Text({ tid: "koblingsboks" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="4">
                                                                        {Text({ tid: "display" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="5">
                                                                        {Text({ tid: "kalibreringskode" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="6">
                                                                        {Text({ tid: "indikator" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="7">
                                                                        {Text({ tid: "veiecelle" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="8">
                                                                        {Text({ tid: "annet" })}
                                                                    </MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={2} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                                <TextField
                                                                    size="small"
                                                                    select
                                                                    fullWidth
                                                                    label={Text({ tid: "type" })}
                                                                    name="nypltype"
                                                                    id="nypltype"
                                                                    defaultValue={"2"}
                                                                    ref={nyPlombeTypeRef}
                                                                    required
                                                                >
                                                                    <MenuItem value="1" aria-label='Elektronisk' aria-labelledby='Elektronisk'>
                                                                        {Text({ tid: "elektronisk" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="2" aria-label='Fysisk' aria-labelledby='Fysisk'>
                                                                        {Text({ tid: "fysisk" })}
                                                                    </MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ mb: 1, pr: 1 }}>
                                                                <TextField
                                                                    size="small"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    id="nyplnummer"
                                                                    label={Text({ tid: "plombenummer" })}
                                                                    name="nyplnummer"
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ mb: 1 }}>
                                                                <TextField
                                                                    size="small"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    id="nyplplassering"
                                                                    label={Text({ tid: "plassering" })}
                                                                    name="nyplplassering"
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} sx={{ ml: 1, pt: 2 }}>
                                                                <Button
                                                                    type="submit"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                >
                                                                    <AddCircleIcon className='jvbtnicon' />
                                                                    {Text({ tid: "leggtil" })}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </CardContent>
                                            </Card>

                                        </CardContent>
                                    </Card>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    size="small"
                                    type="submit"
                                    form="stansevedtakform"
                                    variant="outlined"
                                    color="success"
                                >
                                    <SaveIcon className='jvbtnicon' />
                                    {Text({ tid: "sendmelding" })}
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="error"
                                    onClick={ModalStansevedtakLukk}
                                >
                                    <CloseIcon className='jvbtnicon' />
                                    {Text({ tid: "avbryt" })}
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={ModalEndreLokasjon}
                            onClose={ModalEndreLokasjonLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "endreprimaeromraade" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalEndreLokasjonLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <Box component="form" onSubmit={SendEndreLokasjon}>
                                {!isLoadingLokasjonOptions ?
                                    <DialogContent>
                                        <Alert variant="outlined" severity="info" sx={{ mb: 5 }}>
                                            {Text({ tid: "primaeromraadeinfo" })}
                                        </Alert>
                                        <Grid item xs={12} sx={{ mb: 2 }}>
                                            <Autocomplete
                                                ListboxProps={{ style: { maxHeight: 400 } }}
                                                disablePortal
                                                id="kommune"
                                                onChange={(e, v) => VelgLokasjon(e, v)}
                                                options={kommuner}
                                                defaultValue={AktivKommuneAktorNavn}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    label={Text({ tid: "primaeromraade" })}
                                                    name="kommune"
                                                />}
                                            />
                                        </Grid>
                                    </DialogContent>
                                    :
                                    <Backdrop
                                        open={isLoadingLokasjonOptions}
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop >
                                }
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "lagre" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={ModalEndreLokasjonLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/taksameter"
                            >
                                <Text tid="taksameter" />
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                {aktor[0]?.Navn}
                            </Typography>
                        </Breadcrumbs>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" align="center" color="text.secondary">
                                            {aktor[0]?.Navn}
                                        </Typography>
                                        <Typography align="center" color="text.secondary">
                                            {orgnr}
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <HomeIcon className='jvtexticon' /> {aktor[0]?.Besoksadresse}<br />
                                                    {aktor[0]?.Besokspostnummer} {aktor[0]?.Besokssted}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <PhoneIcon className='jvtexticon' /> {aktor[0]?.Mobilnummer}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <EmailIcon className='jvtexticon' /> {aktor[0]?.Epost}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button variant="contained" color="primary" onClick={ModalEndreAktorAapne} size="small"><EditIcon className='jvbtnicon' /> {Text({ tid: "endre" })}</Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box className="jvstatusboxverdi">{aktor[0]?.Loyver}</Box>
                                        </Grid>
                                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography variant="body1" color="text.secondary" gutterBottom className='jvtypeicon'>
                                                <Text tid="aktiveloyverireg" />
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box className="jvstatusboxverdi">{aktor[0]?.Taksametere}</Box>
                                        </Grid>
                                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography variant="body1" color="text.secondary" gutterBottom className='jvtypeicon'>
                                                <Text tid="aktivetaksametere" />
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Tabs value={valueTab} onChange={handleChangeTab} aria-label="Tabs" sx={{ mb: 2 }}>
                                    <Tab icon={<LocalTaxiOutlined color="success" />} label={"Løyver med taksameter (" + aktor[0]?.Taksametere + ")"} iconPosition="start" />
                                    <Tab icon={<TaxiAlertOutlined color="warning" />} label={"Drosjeløyver uten taksameter (" + aktor[0]?.LoyverUbrukt + ")"} iconPosition="start" />
                                    <Tab icon={<LocalTaxiOutlined color="info" />} label={"Andre løyvetyper"} iconPosition="start" />
                                </Tabs>
                                <TabPanel value={valueTab} index={0}>
                                    {aktor[0]?.instrumenter && aktor[0]?.instrumenter.map(({ Aktivtloyve, installasjonsdato, installasjonsfirma, installasjonsreferanse, sistemeldingdato, sistemeldingfirma, sistemeldingreferanse, sistemeldingtype, sistemeldingtypeid, sistemeldingstatusid, plombebrudddato, DrosjeomraadeId, rettefrist, rettefristdato, rettefristsaksnummer, stansevedtak, stansevedtaksaksnummer, InstrumentId, Bilmerke, Arsmodel, Drosjeomraade, DrosjesentralAktorId, DrosjesentralAktor, SoftwareVersion, KFaktor, Loyvenummer, Dekkdimensjon, Regnr, Serienummer, Strekkode, Taklampenummer, Fabrikat, Modell, LoyvetypeId }: any, index: number) => {
                                        var rettefristdatodisp = rettefristdato?.substr(0, 10).split("-").reverse().join(".");
                                        var cardcolor = "cardtaksameter";
                                        var messageType = 'success';
                                        var turvogn = 0;
                                        var selskapsloyve = 0;
                                        var funksjonsloyve = 0;
                                        var fellesloyve = 0;
                                        var ikkedrosjeloyve = 0;
                                        if (LoyvetypeId === 2) {
                                            ikkedrosjeloyve = 1;
                                            turvogn = 1;
                                        }
                                        if (LoyvetypeId === 4) {
                                            ikkedrosjeloyve = 1;
                                            selskapsloyve = 1;
                                        }
                                        if (LoyvetypeId === 5) {
                                            ikkedrosjeloyve = 1;
                                            funksjonsloyve = 1;
                                        }
                                        if (LoyvetypeId === 10) {
                                            ikkedrosjeloyve = 1;
                                            fellesloyve = 1;
                                        }
                                        if (localStorage.getItem("jvtheme") === "dark") {
                                            cardcolor = "cardtaksameterdark";
                                        }
                                        if (rettefrist) {
                                            if (localStorage.getItem("jvtheme") === "dark") {
                                                cardcolor = "cardrettefristdark";
                                                messageType = "warning";
                                            }
                                            if (localStorage.getItem("jvtheme") === "light") {
                                                cardcolor = "cardrettefrist";
                                                messageType = "warning";
                                            }
                                        }
                                        if (stansevedtak) {
                                            if (localStorage.getItem("jvtheme") === "dark") {
                                                cardcolor = "cardstansevedtakdark";
                                                messageType = "error";
                                            }
                                            if (localStorage.getItem("jvtheme") === "light") {
                                                cardcolor = "cardstansevedtak";
                                                messageType = "error";
                                            }
                                        }
                                        if ((Aktivtloyve !== 1) && (ikkedrosjeloyve === 0)) {
                                            if (localStorage.getItem("jvtheme") === "dark") {
                                                cardcolor = "cardstansevedtakdark";
                                                messageType = "error";
                                            }
                                            if (localStorage.getItem("jvtheme") === "light") {
                                                cardcolor = "cardstansevedtak";
                                                messageType = "error";
                                            }
                                        }
                                        if ((Aktivtloyve !== 1) && (ikkedrosjeloyve === 1)) {
                                            if (localStorage.getItem("jvtheme") === "dark") {
                                                cardcolor = "cardtaksameterdark";
                                                messageType = "success";
                                            }
                                            if (localStorage.getItem("jvtheme") === "light") {
                                                cardcolor = "cardtaksameter";
                                                messageType = "success";
                                            }
                                        }

                                        return <span key={InstrumentId}>
                                            <Card className={cardcolor} sx={{ mb: 2 }}>
                                                <Grid item xs={12}>
                                                    <Alert severity={messageType === "success" ? "success" : messageType === "error" ? "error" : messageType === "info" ? "info" : "warning"}>
                                                        <b>{Loyvenummer}</b> (<b>{Taklampenummer}</b>)
                                                    </Alert>
                                                </Grid>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" color="text.secondary" className='jvtexttitlexl' sx={{ mb: 2 }}>
                                                                <BadgeIcon className='jvtexticontitlexl' />  <Text tid="loyvenummer" />:&nbsp;<b>{Loyvenummer}</b> {(Aktivtloyve === 1) && <Tooltip title={Text({ tid: "verloyvereg" })}><VerifiedIcon className='jviconverified' sx={{ ml: 1 }} /></Tooltip>}
                                                                &nbsp; &nbsp;
                                                                <MinorCrashIcon className='jvtexticontitlexl' />  <Text tid="taklampe" />:&nbsp;<b>{Taklampenummer ? Taklampenummer : "(Ikke oppgitt)"}</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {((rettefrist !== null) && ((Aktivtloyve === 1) || (ikkedrosjeloyve === 1))) &&
                                                                ((sistemeldingtypeid === 45) && (sistemeldingstatusid === 1)) &&
                                                                <Alert sx={{ mb: 2 }} severity="warning">{Text({ tid: "smb1" })} {sistemeldingdato}. {Text({ tid: "referanse" })}: {sistemeldingreferanse}. {Text({ tid: "smb2" })}</Alert>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="subtitle1" color="text.secondary" className='jvtexttitle' gutterBottom>
                                                                <DirectionsCarIcon className='jvtexticontitle' />  <Text tid="kjoretoyinfo" />
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                <b>{Bilmerke}</b> | <b>{Regnr}</b><br />
                                                                <b>{Text({ tid: "aarsmodell" })}</b> <b>{Arsmodel}</b><br />
                                                                <b>{Text({ tid: "dekkdim" })}</b> <b>{Dekkdimensjon}</b><br />
                                                            </Typography>
                                                            <Typography variant="subtitle1" color="text.secondary" className='jvtexttitle' gutterBottom sx={{ mt: 2 }}>
                                                                <PointOfSaleIcon className='jvtexticontitle' />  <Text tid="taksameter" />
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
                                                                <b>{Fabrikat}</b> | <b>{Modell}</b><br />
                                                                {Text({ tid: "serienr" })}: <b>{Serienummer}</b><br />
                                                                {Text({ tid: "jvstrekkode" })}: <b>{Strekkode}</b><br />
                                                                {Text({ tid: "softwareversjon" })}: <b>{SoftwareVersion}</b><br />
                                                                {Text({ tid: "kfaktor" })}: <b>{KFaktor}</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Box sx={{ mb: 2 }}>
                                                                <Typography variant="subtitle1" color="text.secondary" className='jvtexttitle' gutterBottom>
                                                                    <LinkIcon className='jvtexticontitle' />  <Text tid="plomberinger" />
                                                                </Typography>
                                                                {aktor[0]?.instrumenter[index].plomberinger[0] ?
                                                                    <TableContainer>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                                                    <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                                                    <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                                                    <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {aktor[0]?.instrumenter[index].plomberinger && aktor[0]?.instrumenter[index].plomberinger.map(({ PlomberingId, Plassering, Plombenavn, Plombetype, Plombenummer }: any, index: number) => {
                                                                                    return <TableRow key={PlomberingId}>
                                                                                        <TableCell className='jvtablerow'>{Plombenavn}</TableCell>
                                                                                        <TableCell className='jvtablerow'>{Plombetype}</TableCell>
                                                                                        <TableCell className='jvtablerow'>{Plombenummer}</TableCell>
                                                                                        <TableCell className='jvtablerow'>{Plassering}</TableCell>
                                                                                    </TableRow>
                                                                                })
                                                                                }

                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                    :
                                                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                        <i>{Text({ tid: "ingenplomber" })}</i>
                                                                    </Typography>
                                                                }
                                                            </Box>
                                                            <Typography variant="subtitle1" color="text.secondary" className='jvtexttitle' gutterBottom>
                                                                <TravelExploreIcon className='jvtexticontitle' />  {Text({ tid: "lokasjon" })}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
                                                                {Text({ tid: "drosjesentral" })}: <b>{DrosjesentralAktor}</b>
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                {Text({ tid: "primaeromraade" })}: <b>{Drosjeomraade}</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2} sx={{ ml: 5 }}>
                                                            <Box>
                                                                {(installasjonsdato) &&
                                                                    <Box sx={{ p: 1, mr: 2 }}>
                                                                        <Typography variant="subtitle1" color="text.secondary" className='jvtexttitle' gutterBottom>
                                                                            <InstallMobileIcon className='jvtexticontitle' />  {Text({ tid: "installasjonskontroll" })}
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                            {Text({ tid: "referanse" })}: <b>#{installasjonsreferanse}</b>
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
                                                                            {Text({ tid: "installasjonsdato" })}: <b>{installasjonsdato}</b>
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
                                                                            {Text({ tid: "meldtinnav" })}: <b>{installasjonsfirma}</b>
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                                {(sistemeldingdato !== undefined) &&
                                                                    <Box sx={{ p: 1, mr: 2 }}>
                                                                        <Typography variant="subtitle1" color="text.secondary" className='jvtexttitle' gutterBottom>
                                                                            <InstallMobileIcon className='jvtexticontitle' />  {Text({ tid: "sistemelding" })}
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                            {Text({ tid: "referanse" })}: <b>#{sistemeldingreferanse}</b>
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                                                            {Text({ tid: "type" })}: <b>{sistemeldingtype}</b>
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
                                                                            {Text({ tid: "dato" })}: <b>{sistemeldingdato}</b>
                                                                        </Typography>
                                                                        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mb: 1 }}>
                                                                            {Text({ tid: "meldtinnav" })}: <b>{sistemeldingfirma}</b>
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                            <Button size="small" variant="contained" color="primary" sx={{ mr: 1 }} onClick={e => ModalHistorikkAapne(InstrumentId, index)}><MoreTimeIcon className='jvbtnicon' /> Historikk</Button>
                                                        </Grid>
                                                        <Grid item xs={12} md={12} sx={{ pt: 2 }}>
                                                            <ButtonGroup variant="outlined" size="small">
                                                                {((rettefrist !== null) && ((Aktivtloyve === 1) || (ikkedrosjeloyve === 1))) &&
                                                                    ((sistemeldingtypeid !== 45) || ((sistemeldingtypeid === 45) && (sistemeldingstatusid !== 1))) &&
                                                                    <Button variant="contained" color="primary" sx={{ mr: 1 }} onClick={e => ModalUtvidRettefristAapne(InstrumentId, index, rettefrist, rettefristdatodisp, rettefristsaksnummer)}><MoreTimeIcon className='jvbtnicon' /> {Text({ tid: "sokutvidetrettefrist" })}</Button>
                                                                }
                                                                {((rettefrist !== null) && ((Aktivtloyve === 1) || (ikkedrosjeloyve === 1))) &&
                                                                    <Button variant="contained" color="primary" sx={{ mr: 1 }} onClick={e => ModalRettefristAapne(InstrumentId, index, rettefrist, rettefristdatodisp, rettefristsaksnummer)}><ConstructionIcon className='jvbtnicon' /> {Text({ tid: "utfortretting" })}</Button>
                                                                }
                                                                {((stansevedtak !== null) && ((Aktivtloyve === 1) || (ikkedrosjeloyve === 1))) &&
                                                                    <Button variant="contained" color="primary" sx={{ mr: 1 }} onClick={e => ModalStansevedtakAapne(InstrumentId, index, stansevedtak, stansevedtaksaksnummer)}><ConstructionIcon className='jvbtnicon' /> {Text({ tid: "meldutfortretting" })}</Button>
                                                                }
                                                                {(((Aktivtloyve === 1) || (ikkedrosjeloyve === 1)) && (!rettefrist) && (!stansevedtak)) ?
                                                                    <Button variant="contained" color="primary" sx={{ mr: 1 }} onClick={e => ModalPlombebruddAapne(InstrumentId, index)}><LinkOffIcon className='jvbtnicon' /> {Text({ tid: "sendmelding" })}</Button>
                                                                    :
                                                                    <Button variant="contained" color="primary" sx={{ mr: 1 }} disabled><LinkOffIcon className='jvbtnicon' /> {Text({ tid: "sendmelding" })}</Button>
                                                                }
                                                                {(((Aktivtloyve === 1) || (ikkedrosjeloyve === 1)) && (!rettefrist) && (!stansevedtak)) ?
                                                                    <Button variant="contained" color="primary" sx={{ mr: 1 }} onClick={e => ModalEndreLokasjonAapne(InstrumentId, DrosjeomraadeId, Drosjeomraade)}><TravelExploreIcon className='jvbtnicon' /> {Text({ tid: "endreomraade" })}</Button>
                                                                    :
                                                                    <Button variant="contained" color="primary" sx={{ mr: 1 }} disabled><TravelExploreIcon className='jvbtnicon' /> {Text({ tid: "endreomraade" })}</Button>
                                                                }
                                                                {((!rettefrist) && (!stansevedtak)) ?
                                                                    <Button variant="contained" color="error" sx={{ mr: 1 }} onClick={e => ModalUtmonteringAapne(InstrumentId, index)}><DeleteForeverIcon className='jvbtnicon' /> {Text({ tid: "utmontering" })}</Button>
                                                                    :
                                                                    <Button variant="contained" color="error" sx={{ mr: 1 }} disabled><DeleteForeverIcon className='jvbtnicon' /> {Text({ tid: "utmontering" })}</Button>
                                                                }
                                                            </ButtonGroup>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                                {((rettefrist !== null) && ((Aktivtloyve === 1) || (ikkedrosjeloyve === 1))) &&
                                                    <Grid item xs={12}>
                                                        {(moment().diff(rettefristdato, 'days') < 1) ?
                                                            <Alert severity="warning">
                                                                {Text({ tid: "aktivrettefrist" })} {Text({ tid: "referanse" })} #{rettefrist} - {Text({ tid: "saksnummer" })}: {rettefristsaksnummer} - {Text({ tid: "frist" })}: {rettefristdatodisp}
                                                            </Alert>
                                                            :
                                                            <Alert severity="error">
                                                                {Text({ tid: "rtutlopt1" })} {rettefristdatodisp}. {Text({ tid: "rtutlopt2" })} {Text({ tid: "saksnummer" })}: {rettefristsaksnummer}. {Text({ tid: "referanse" })}: {rettefrist}. {Text({ tid: "sbtil" })}
                                                            </Alert>
                                                        }
                                                    </Grid>
                                                }
                                                {((stansevedtak !== null) && ((Aktivtloyve === 1) || (ikkedrosjeloyve === 1))) &&
                                                    <Grid item xs={12}>
                                                        <Alert severity="error">
                                                            {Text({ tid: "aktivstansevedtak" })} {Text({ tid: "referanse" })} #{stansevedtak}
                                                        </Alert>
                                                    </Grid>
                                                }
                                                {((Aktivtloyve !== 1) && (ikkedrosjeloyve === 0)) &&
                                                    <Grid item xs={12}>
                                                        <Alert severity="error">
                                                            {Text({ tid: "taksugyldigloyve" })}
                                                        </Alert>
                                                    </Grid>
                                                }
                                                {(turvogn === 1) &&
                                                    <Grid item xs={12}>
                                                        <Alert severity="info">
                                                            {Text({ tid: "taksturvogn" })}
                                                        </Alert>
                                                    </Grid>
                                                }
                                                {(fellesloyve === 1) &&
                                                    <Grid item xs={12}>
                                                        <Alert severity="info">
                                                            {Text({ tid: "taksfelles" })}
                                                        </Alert>
                                                    </Grid>
                                                }
                                                {(funksjonsloyve === 1) &&
                                                    <Grid item xs={12}>
                                                        <Alert severity="info">
                                                            {Text({ tid: "taksfunksjon" })}
                                                        </Alert>
                                                    </Grid>
                                                }
                                                {(selskapsloyve === 1) &&
                                                    <Grid item xs={12}>
                                                        <Alert severity="info">
                                                            {Text({ tid: "taksselskap" })}
                                                        </Alert>
                                                    </Grid>
                                                }
                                            </Card>
                                        </span>
                                    })
                                    }
                                </TabPanel>
                                <TabPanel value={valueTab} index={1}>
                                    {aktor[0]?.UbrukteLoyver && aktor[0]?.UbrukteLoyver.map(({ Loyvenummer, Loyvetype, Taklampenummer }: any, index: number) => {
                                        return <span key={Loyvenummer}>
                                            <Card variant="outlined" sx={{ mb: 2 }}>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid item xs={4}>
                                                            <Typography variant="body2" color="text.secondary">
                                                                <b>{Text({ tid: "loyvenummer" })}</b><br />
                                                            </Typography>
                                                            <Typography variant="h6" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                                {Loyvenummer}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="body2" color="text.secondary">
                                                                <b>{Text({ tid: "taklampe" })}</b><br />
                                                            </Typography>
                                                            <Typography variant="h6" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                                {Taklampenummer}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="body2" color="text.secondary">
                                                                <b>{Text({ tid: "loyvetype" })}</b><br />
                                                            </Typography>
                                                            <Typography variant="h6" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                                {Loyvetype}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box mb={1}>
                                                                <Button variant="outlined" color="primary" onClick={e => ModalInstallasjonAapne(Loyvenummer, Taklampenummer)}><ManageAccountsIcon className='jvbtnicon' /> {Text({ tid: "meldinstallasjonskontroll" })}</Button>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </span>
                                    })
                                    }
                                </TabPanel>
                                <TabPanel value={valueTab} index={2}>
                                    <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                        {Text({ tid: "instturvogninfo" })}
                                    </Alert>
                                    {((aktor[0]?.Epost !== "") && (aktor[0]?.Mobilnummer !== "")) ?
                                        <div>
                                            <Box component="form" id="installasjonform" onSubmit={SendInstallasjonTurvogn}>
                                                <Grid container sx={{ mb: 2 }}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        maxRows={10}
                                                        label={Text({ tid: "loyvetype" })}
                                                        name="loyvetype"
                                                        id="loyvetype"
                                                        required
                                                        sx={{ mb: 2 }}
                                                    >
                                                        <MenuItem key="2" value="2">
                                                            Turvogn
                                                        </MenuItem>
                                                        <MenuItem key="10" value="10">
                                                            Fellesskapsløyve for turvogn
                                                        </MenuItem>
                                                        <MenuItem key="5" value="5">
                                                            Transport for funksjonshemmede
                                                        </MenuItem>
                                                    </TextField>
                                                    <FormControl required>
                                                        <FormLabel id="instaarsaklabel"><Text tid={"aarsak"} /></FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="instaarsaklabel"
                                                            name="instaarsak"
                                                            onChange={(e) => handleValgtInstAarsak(e)}
                                                        >
                                                            <FormControlLabel value="3" control={<Radio />} label={Text({ tid: "loyvetsettesidrift" })} />
                                                            <FormControlLabel value="1" control={<Radio />} label={Text({ tid: "nyttkjoretoy" })} />
                                                            <FormControlLabel value="2" control={<Radio />} label={Text({ tid: "nytttaksameter" })} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                {(ValgtInstAarsak !== 0) &&
                                                    <div>
                                                        <Grid container sx={{ mb: 2 }}>
                                                            <TextField
                                                                size="small"
                                                                type="date"
                                                                id="instdato"
                                                                label={Text({ tid: "installasjonsdato" })}
                                                                name="instdato"
                                                                defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                                                                required
                                                            />
                                                        </Grid>
                                                        <Grid container sx={{ mb: 2 }}>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    id="loyvenummer"
                                                                    label={Text({ tid: "loyvenummer" })}
                                                                    name="loyvenummer"
                                                                    sx={{ pr: 2 }}
                                                                    defaultValue={AktivtLoyvenummer}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    id="taklampe"
                                                                    label={Text({ tid: "taklampe" })}
                                                                    name="taklampe"
                                                                    sx={{ pr: 2 }}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} sx={{ pr: 2 }}>
                                                                <Autocomplete
                                                                    size="small"
                                                                    ListboxProps={{ style: { maxHeight: 150 } }}
                                                                    disablePortal
                                                                    id="kommune"
                                                                    onChange={(e, v) => VelgLokasjon(e, v)}
                                                                    options={kommuner}
                                                                    renderInput={(params) => <TextField
                                                                        {...params}
                                                                        label={Text({ tid: "primaeromraade" })}
                                                                        name="kommune"
                                                                        required
                                                                    />}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container sx={{ mb: 2 }}>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    fullWidth
                                                                    id="drosjesentralorg"
                                                                    label={Text({ tid: "drosjesentralorg" })}
                                                                    name="drosjesentralorg"
                                                                    onChange={(e) => handleDrosjesentralSok(e)}
                                                                    sx={{ pr: 2 }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    id="drosjesentralnavn"
                                                                    label={Text({ tid: "drosjesentralnavn" })}
                                                                    name="drosjesentralnavn"
                                                                    value={BrregRespons[0]?.navn}
                                                                    defaultValue=" "
                                                                    sx={{ pr: 2 }}
                                                                    disabled
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container sx={{ mb: 2 }}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    id="bilmerke"
                                                                    label={Text({ tid: "bilmerke" })}
                                                                    name="bilmerke"
                                                                    sx={{ pr: 2 }}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    size="small"
                                                                    type="number"
                                                                    fullWidth
                                                                    id="aarsmodell"
                                                                    label={Text({ tid: "aarsmodell" })}
                                                                    name="aarsmodell"
                                                                    sx={{ pr: 2 }}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    id="regnr"
                                                                    label={Text({ tid: "regnr" })}
                                                                    name="regnr"
                                                                    sx={{ pr: 2 }}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    id="dekkdim"
                                                                    label={Text({ tid: "dekkdim" })}
                                                                    name="dekkdim"
                                                                    sx={{ pr: 2 }}
                                                                    required
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container sx={{ mb: 2 }}>
                                                            <Grid item xs={4} sx={{ pr: 2 }}>
                                                                <Autocomplete
                                                                    size="small"
                                                                    ListboxProps={{ style: { maxHeight: 150 } }}
                                                                    disablePortal
                                                                    id="fabrikat"
                                                                    onChange={(e, v) => VelgTaksameter(e, v)}
                                                                    options={TaksameterFabrikater}
                                                                    renderInput={(params) => <TextField
                                                                        {...params}
                                                                        label={Text({ tid: "taksameterfabrikat" })}
                                                                        name="fabrikat"
                                                                        required
                                                                    />}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} pr={2}>
                                                                <Autocomplete
                                                                    size="small"
                                                                    ListboxProps={{ style: { maxHeight: 150 } }}
                                                                    disablePortal
                                                                    id="modell"
                                                                    onChange={(e, v) => VelgModell(e, v)}
                                                                    options={TaksameterModeller}
                                                                    renderInput={(params) => <TextField
                                                                        {...params}
                                                                        label={Text({ tid: "taksametermodell" })}
                                                                        name="modell"
                                                                        required
                                                                    />}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    id="serienr"
                                                                    label={Text({ tid: "serienr" })}
                                                                    name="serienr"
                                                                    sx={{ pr: 2 }}
                                                                    required
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container sx={{ mb: 2 }}>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    id="swver"
                                                                    label={Text({ tid: "softwareversjon" })}
                                                                    name="swver"
                                                                    sx={{ pr: 2 }}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    id="kfaktor"
                                                                    label={Text({ tid: "kfaktor" })}
                                                                    name="kfaktor"
                                                                    sx={{ pr: 2 }}
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextField
                                                                    size="small"
                                                                    fullWidth
                                                                    id="jvstrekkode"
                                                                    label={Text({ tid: "jvstrekkode" })}
                                                                    name="jvstrekkode"
                                                                    sx={{ pr: 2 }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container sx={{ mb: 2 }}>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    multiline
                                                                    rows="2"
                                                                    size="small"
                                                                    fullWidth
                                                                    id="ekstraopplysninger"
                                                                    label={Text({ tid: "kommentarer" })}
                                                                    name="ekstraopplysninger"
                                                                    sx={{ pr: 2 }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Typography>
                                                            {Text({ tid: "plomber" })}
                                                        </Typography>
                                                        <TableContainer sx={{ mb: 3 }}>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className='jvtablehead'>{Text({ tid: "navn" })}</TableCell>
                                                                        <TableCell className='jvtablehead'>{Text({ tid: "type" })}</TableCell>
                                                                        <TableCell className='jvtablehead'>{Text({ tid: "nummer" })}</TableCell>
                                                                        <TableCell className='jvtablehead'>{Text({ tid: "plassering" })}</TableCell>
                                                                        <TableCell className='jvtablehead'>{Text({ tid: "valg" })}</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {NyePlomber && NyePlomber.map(({ nyplnavn, nyplnummer, nypltype, nyplplassering }: any, indexnypl: number) => {
                                                                        return <TableRow key={indexnypl} sx={{ mb: 1 }}>
                                                                            <TableCell className='jvtablerow'>
                                                                                {nyplnavn}
                                                                            </TableCell>
                                                                            <TableCell className='jvtablerow'>{nypltype}</TableCell>
                                                                            <TableCell className='jvtablerow'>{nyplnummer}</TableCell>
                                                                            <TableCell className='jvtablerow'>{nyplplassering}</TableCell>
                                                                            <TableCell className='jvtablerow'>
                                                                                <FormControlLabel
                                                                                    key={"nyplid_" + indexnypl}
                                                                                    value={indexnypl}
                                                                                    control={<Checkbox icon={<DeleteOutlineOutlinedIcon />} />}
                                                                                    checked={false}
                                                                                    label=""
                                                                                    onChange={handleCheckSlettNyPlombe}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    })
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                }
                                            </Box>
                                            {(ValgtInstAarsak !== 0) &&
                                                <div>
                                                    <Typography>
                                                        {Text({ tid: "leggtilnyplombe" })}
                                                    </Typography>
                                                    <Box component="form" id="plombebruddform" onSubmit={LeggtilNyPlombe}>
                                                        <Grid container>
                                                            <Grid item xs={2} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                                <TextField
                                                                    size="small"
                                                                    select
                                                                    fullWidth
                                                                    label="Plombenavn"
                                                                    name="nyplnavn"
                                                                    id="nyplnavn"
                                                                    defaultValue={"1"}
                                                                    ref={nyPlombeNavnRef}
                                                                    required
                                                                >
                                                                    <MenuItem value="1">
                                                                        {Text({ tid: "taksameter" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="2">
                                                                        {Text({ tid: "spenning" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="3">
                                                                        {Text({ tid: "koblingsboks" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="4">
                                                                        {Text({ tid: "display" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="5">
                                                                        {Text({ tid: "kalibreringskode" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="6">
                                                                        {Text({ tid: "indikator" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="7">
                                                                        {Text({ tid: "veiecelle" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="8">
                                                                        {Text({ tid: "annet" })}
                                                                    </MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={2} sx={{ mb: 1, pr: 1, pt: 2 }}>
                                                                <TextField
                                                                    size="small"
                                                                    select
                                                                    fullWidth
                                                                    label="Type"
                                                                    name="nypltype"
                                                                    id="nypltype"
                                                                    defaultValue={"2"}
                                                                    ref={nyPlombeTypeRef}
                                                                    required
                                                                >
                                                                    <MenuItem value="1" aria-label='Elektronisk' aria-labelledby='Elektronisk'>
                                                                        {Text({ tid: "elektronisk" })}
                                                                    </MenuItem>
                                                                    <MenuItem value="2" aria-label='Fysisk' aria-labelledby='Fysisk'>
                                                                        {Text({ tid: "fysisk" })}
                                                                    </MenuItem>
                                                                </TextField>
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ mb: 1, pr: 1 }}>
                                                                <TextField
                                                                    size="small"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    id="nyplnummer"
                                                                    label={Text({ tid: "plombenummer" })}
                                                                    name="nyplnummer"
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ mb: 1 }}>
                                                                <TextField
                                                                    size="small"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    id="nyplplassering"
                                                                    label={Text({ tid: "plassering" })}
                                                                    name="nyplplassering"
                                                                    required
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} sx={{ ml: 1, pt: 2 }}>
                                                                <Button
                                                                    type="submit"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                >
                                                                    <AddCircleIcon className='jvbtnicon' />
                                                                    {Text({ tid: "leggtil" })}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <DialogActions>
                                                        <Button
                                                            size="small"
                                                            type="submit"
                                                            form="installasjonform"
                                                            variant="contained"
                                                            color="success"
                                                            disabled={!NyePlomber.length}
                                                        >
                                                            <SaveIcon className='jvbtnicon' />
                                                            {Text({ tid: "opprettturvogn" })}
                                                        </Button>
                                                    </DialogActions>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <Alert variant="outlined" severity="error" sx={{ mb: 2 }}>
                                                {Text({ tid: "loyvekontaktmangler" })}
                                            </Alert>
                                            <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                                {Text({ tid: "loyvekontaktmanglerpersonvern" })}
                                            </Alert>
                                            <Box component="form" id="kontaktoppdateringform" onSubmit={sendAktorOppdaterKontaktinfo}>
                                                <Grid container>
                                                    <Grid item xs={12} p={2}>
                                                        <TextField
                                                            size="small"
                                                            fullWidth
                                                            id="aktorepost"
                                                            label={Text({ tid: "epost" })}
                                                            name="epost"
                                                            defaultValue={aktor[0]?.Epost}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} p={2}>
                                                        <TextField
                                                            size="small"
                                                            fullWidth
                                                            id="aktormobil"
                                                            label={Text({ tid: "mobiltelefon" })}
                                                            name="mobil"
                                                            defaultValue={aktor[0]?.Mobilnummer}
                                                            required
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <DialogActions>
                                                <Button
                                                    size="small"
                                                    type="submit"
                                                    form="kontaktoppdateringform"
                                                    variant="contained"
                                                    color="success"
                                                >
                                                    <SaveIcon className='jvbtnicon' />
                                                    {Text({ tid: "oppdater" })}
                                                </Button>
                                            </DialogActions>
                                        </div>
                                    }
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </div>
            }
        </main >
    )
}

export default TaksameterDetaljer;