import { Alert, Backdrop, Box, Button, CircularProgress, FormControl, Grid, Input, InputLabel, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import LoginIcon from '@mui/icons-material/Login';
import Footer from '../komponenter/Bunn';
import axios from 'axios';

const APIURL = process.env.REACT_APP_APIURL;

function Nykonto() {
    useEffect(() => {
        document.title = 'Din Side - Justervesenet';
    }, []);
    const [isWaiting, setisWaiting] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        setisWaiting(true);
        event.preventDefault();
        var formdata = new FormData(event.currentTarget);
        axios.post(APIURL + "/profil/nykonto", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisWaiting(false);
                if (response.status === 200) {
                    window.location.assign("/");
                }
                else {
                }
            })
            .catch((e: Error) => {
                setSnackMelding("Feil under opprettelse av brukerkonto");
                setOpenSnackError(true);
                setisWaiting(false);
            });
    }

    return (
        <main>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isWaiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ToppMeny />
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                    {snackMelding}
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackError} autoHideDuration={6000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                    {snackMelding}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Box m={2}>
                        <Typography variant="h5" color="text.primary" component="h2" gutterBottom>
                            <Text tid="opprettnykontooverskrift" /><p></p>
                        </Typography>

                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                        <InputLabel htmlFor="fulltnavn"><Text tid="fulltnavn" /></InputLabel>
                                        <Input
                                            autoFocus
                                            id="navn"
                                            name="navn"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                        <InputLabel htmlFor="epost"><Text tid="epost" /></InputLabel>
                                        <Input
                                            id="epost"
                                            name="epost"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                        <InputLabel htmlFor="mobilnummer"><Text tid="mobilnummer" /></InputLabel>
                                        <Input
                                            id="mobilnummer"
                                            name="mobilnummer"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid container spacing={2} mb={5}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                        <InputLabel htmlFor="passord"><Text tid="passord" /></InputLabel>
                                        <Input
                                            id="passord"
                                            name="passord"
                                            type="password"
                                            fullWidth
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="outlined"
                                startIcon={<LoginIcon />}
                                sx={{ mr: 1 }}
                            >
                                <Text tid="opprettkonto" />
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Footer />
        </main>
    )
}

export default Nykonto;