import { Alert, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Input, InputLabel, Link, Snackbar, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VerifiedIcon from '@mui/icons-material/Verified';

const APIURL = process.env.REACT_APP_APIURL;

function Profil() {
    const [isLoading, setisLoading] = useState(true);
    const [isLoadingAktorSok, setisLoadingAktorSok] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [aktorSlettModal, setaktorSlettModal] = useState(false);
    const [aktorTilknyttModal, setaktorTilknyttModal] = useState(false);
    const [profilOppdatert, setProfilOppdatert] = useState(false);
    const [aktorSlettAktorId, setaktorSlettAktorId] = useState(0);
    const [aktorSlettAktorNavn, setaktorSlettAktorNavn] = useState(null);
    const [jvbruker, setjvbruker] = useState(sessionStorage.getItem('jvbruker'));
    const [jvnavn, setjvnavn] = useState(sessionStorage.getItem('jvnavn'));
    const [jvmobilnummer, setjvmobilnummer] = useState(sessionStorage.getItem('jvmobilnummer'));
    const jvsesaktorid = sessionStorage.getItem('jvaktorid');

    const [aktorsok, setAktorsok] = useState<any[]>([]);

    const handleCloseProfileUpdated = () => {
        setProfilOppdatert(false);
    };

    const [aktorer, setAktorer] = useState<any[]>([]);

    const token = sessionStorage.getItem("jvtoken") || "";

    const handleClickProfileModalClose = () => {
        setProfileModal(false);
    };

    const handleClickProfileModalOpen = () => {
        setProfileModal(true);
    };

    const handleClickPasswordModalOpen = () => {
        setPasswordModal(true);
    };

    const handleClickPasswordModalClose = () => {
        setPasswordModal(false);
    };


    const handleOpenModalNyAktor = () => {
        setaktorTilknyttModal(true);
    };

    const handleCloseModalNyAktor = () => {
        setaktorTilknyttModal(false);
    };

    const handleClickaktorSlettModalClose = () => {
        setaktorSlettModal(false);
    };

    const handleClickaktorSlettModalOpen = (aktorid: number, aktornavn: any) => {
        setaktorSlettAktorId(aktorid);
        setaktorSlettAktorNavn(aktornavn);
        setaktorSlettModal(true);
    };

    const handleClickAktivAktor = (aktorid: any, aktornavn: any, aktororgnr: any) => {
        sessionStorage.setItem('jvaktorid', aktorid);
        sessionStorage.setItem('jvaktornavn', aktornavn);
        sessionStorage.setItem('jvaktororgnummer', aktororgnr);
        getaktorer();
    }


    const handleSubmitPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        handleClickPasswordModalClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            passord: data.get('passord')
        };
        axios.post(APIURL + "/passord", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfilOppdatert(true);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const handleSubmitProfile = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        handleClickProfileModalClose();
        var data = new FormData(event.currentTarget);
        var formdata = {
            Navn: data.get('Navn'),
            Epost: data.get('Epost'),
            Mobilnummer: data.get('Mobilnummer')
        };
        axios.put(APIURL + "/profil", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfilOppdatert(true);
                    console.log(response.data);
                    sessionStorage.setItem('jvbruker', response.data[0].epost);
                    sessionStorage.setItem('jvnavn', response.data[0].navn);
                    sessionStorage.setItem('jvmobilnummer', response.data[0].mobilnummer);
                    setjvbruker(response.data[0].epost);
                    setjvnavn(response.data[0].navn);
                    setjvmobilnummer(response.data[0].mobilnummer);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const handleSubmitTilknyttAktor = (orgnr: number) => {
        setisLoading(true);
        handleCloseModalNyAktor();
        var formdata = {
            orgnr: orgnr,
        };
        axios.post(APIURL + "/profil/aktortilknytt", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfilOppdatert(true);
                    if ((jvsesaktorid === "undefined") || (jvsesaktorid === null)) {
                        sessionStorage.setItem('jvaktorid', response.data[0].aktorid);
                        sessionStorage.setItem('jvaktornavn', response.data[0].navn);
                        sessionStorage.setItem('jvaktororgnummer', orgnr.toString());
                    }
                    getaktorer();
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    const handleSokAktor = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoadingAktorSok(true);
        var data = new FormData(event.currentTarget);
        var formdata = {
            aktorsok: data.get('aktorsok'),
        };
        axios.post(APIURL + "/profil/aktorsok", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorsok(response.data);
                }
                else {
                }
                setisLoadingAktorSok(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoadingAktorSok(false);
            });
    }

    const handleSubmitAktorSlett = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        handleClickaktorSlettModalClose();
        var formdata = {
            AktorId: aktorSlettAktorId,
        };
        await axios.post(APIURL + "/profil/aktorslett", formdata, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setProfilOppdatert(true);
                    if (aktorSlettAktorId.toString() === jvsesaktorid) {
                        sessionStorage.removeItem('jvaktorid');
                        sessionStorage.removeItem('jvaktornavn');
                        sessionStorage.removeItem('jvaktororgnummer');
                        getaktorer(1);
                    }
                    else {
                        getaktorer();
                    }
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    function getaktorer(setaktor?: number | undefined) {
        axios.get(APIURL + "/profil/aktorer", { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktorer(response.data);
                    if (setaktor === 1) {
                        sessionStorage.setItem('jvaktorid', response.data[0].AktorId);
                        sessionStorage.setItem('jvaktornavn', response.data[0].Navn);
                        sessionStorage.setItem('jvaktororgnummer', response.data[0].OrgNummer);
                    }
                }
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
            });
    }

    useEffect(() => {
        document.title = 'Din Side - Justervesenet';
        getaktorer();
        setisLoading(false);
        // eslint-disable-next-line
    }, []);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                <Text tid="minprofil" />
                            </Typography>
                        </Breadcrumbs>
                        <Snackbar open={profilOppdatert} autoHideDuration={6000} onClose={handleCloseProfileUpdated} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                            <Alert onClose={handleCloseProfileUpdated} severity="success" sx={{ width: '100%' }}>
                                <Text tid="profiloppdatert" />
                            </Alert>
                        </Snackbar>
                        <Dialog
                            open={profileModal}
                            onClose={handleClickProfileModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="endreprofil" />
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSubmitProfile} noValidate>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                            <InputLabel htmlFor="navn"><Text tid="fulltnavn" /></InputLabel>
                                            <Input
                                                autoFocus
                                                id="Navn"
                                                name="Navn"
                                                defaultValue={jvnavn}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="Epost"><Text tid="epost" /></InputLabel>
                                            <Input
                                                id="Epost"
                                                name="Epost"
                                                defaultValue={jvbruker}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="Mobilnummer"><Text tid="mobilnummer" /></InputLabel>
                                            <Input
                                                id="Mobilnummer"
                                                name="Mobilnummer"
                                                defaultValue={jvmobilnummer}
                                                fullWidth
                                                required
                                            />
                                        </FormControl>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        <Text tid="lagre" />
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleClickProfileModalClose}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        <Dialog
                            open={passwordModal}
                            onClose={handleClickPasswordModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="endrepassord" />
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSubmitPassword}>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                            <InputLabel htmlFor="navn"><Text tid="passord" /></InputLabel>
                                            <Input autoFocus id="passord" name="passord" type="password" fullWidth required />
                                        </FormControl>
                                        <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                            <InputLabel htmlFor="Epost"><Text tid="bekreftpassord" /></InputLabel>
                                            <Input id="password2" name="bekreftpassord" type="password" fullWidth required />
                                        </FormControl>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button size="small" type="submit" variant="outlined" color="success">
                                        <SaveIcon className='jvbtnicon' />
                                        <Text tid="lagre" />
                                    </Button>
                                    <Button size="small" variant="outlined" color="error" onClick={handleClickPasswordModalClose}>
                                        <CloseIcon className='jvbtnicon' />
                                        <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        <Dialog
                            open={aktorSlettModal}
                            onClose={handleClickaktorSlettModalClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="aktortilknyttfjern" />
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSubmitAktorSlett}>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Text tid="aktortilknyttfjernbekreftelse" /> {aktorSlettAktorNavn}?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        variant="outlined"
                                        color="success"
                                    >
                                        <CheckIcon className='jvbtnicon' />
                                        <Text tid="bekreft" />
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleClickaktorSlettModalClose}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        <Dialog
                            open={aktorTilknyttModal}
                            onClose={handleCloseModalNyAktor}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="md"
                            fullWidth
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Text tid="tilknyttnyaktor" />
                            </DialogTitle>
                            <Box component="form" onSubmit={handleSokAktor} noValidate>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom sx={{ mb: 2 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Alert severity="info">
                                                        <Text tid='ainfosok' />
                                                    </Alert>
                                                </Grid>
                                            </Grid>
                                        </Typography>
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <FormControl fullWidth sx={{ mb: 2 }} variant="standard" >
                                                    <InputLabel htmlFor="navn"><Text tid="sokaktororg" /></InputLabel>
                                                    <Input
                                                        autoFocus
                                                        id="aktorsok"
                                                        name="aktorsok"
                                                        fullWidth
                                                        required
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4} pl={2} pt={2}>
                                                <Button
                                                    type="submit"
                                                    size="small"
                                                    variant="outlined"
                                                    color="success"
                                                >
                                                    <SearchIcon className='jvbtnicon' />
                                                    <Text tid="sok" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        {isLoadingAktorSok ?
                                            <div>
                                                <Backdrop
                                                    open={isLoadingAktorSok}
                                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                                                >
                                                    <CircularProgress color="inherit" />
                                                </Backdrop >
                                            </div>
                                            :
                                            null
                                        }
                                        {aktorsok && aktorsok.map(({ navn, orgnr }: any, index: number) => {
                                            return <span key={orgnr}>
                                                <Card variant="outlined" sx={{ mb: 2 }}>
                                                    <CardContent>
                                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                                            {navn}
                                                        </Typography>

                                                        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Typography variant="h6" color="text.secondary" gutterBottom className='jvtypeicon'>
                                                                {orgnr}
                                                            </Typography>
                                                            <Button variant="outlined" color="success" size="small" onClick={e => handleSubmitTilknyttAktor(orgnr)}><AddIcon className='jvbtnicon' /> <Text tid="tilknytt" /></Button>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </span>
                                        })
                                        }
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="error"
                                        onClick={handleCloseModalNyAktor}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        <Text tid="avbryt" />
                                    </Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12}>
                                {((jvsesaktorid === "undefined") || (jvsesaktorid === null)) &&
                                    <Typography>
                                        <Alert severity="error"><Text tid="manglertilknytning" /><p></p>
                                            <Button variant="outlined" size="small" color="primary" onClick={handleOpenModalNyAktor}><AddCircleIcon className='jvbtnicon' /> <Text tid="tilknyttnyaktor" /></Button>
                                        </Alert>
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Typography variant="h6" component="h2">
                                    <Typography variant="h6" color="text.secondary" gutterBottom className='jvcardtitle'>
                                        <AccountCircle className='jvcardtitleicon' /> <Text tid="minprofil" />
                                    </Typography>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                                {jvnavn}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                <EmailIcon className='jvtexticonnc' /> {jvbruker}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                <PhoneIcon className='jvtexticonnc' /> {jvmobilnummer}
                                            </Typography>
                                            <p></p>
                                            <Button variant="outlined" color="warning" onClick={handleClickProfileModalOpen} sx={{ mr: 2 }}><EditIcon className='jvbtnicon' /> <Text tid="endreprofil" /></Button>
                                            <Button variant="outlined" color="warning" onClick={handleClickPasswordModalOpen}><EditIcon className='jvbtnicon' /> <Text tid="endrepassord" /></Button>
                                        </CardContent>
                                    </Card>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="h6" color="text.secondary" gutterBottom className='jvcardtitle'>
                                        <BusinessIcon className='jvcardtitleicon' /> <Text tid="aktor" />
                                    </Typography>
                                    <IconButton color="primary" aria-label="Tilknytt ny aktør" onClick={handleOpenModalNyAktor}>
                                        <AddCircleIcon className='jvtitleactionicon' />
                                    </IconButton>
                                </Grid>
                                {aktorer.map(({ AktorId, Navn, OrgNummer, Rolle, Verifisert, Admin }: any, index: number) => {
                                    return <span key={AktorId}>
                                        <Card variant="outlined" sx={{ mb: 2 }}>
                                            {!Verifisert &&
                                                <Alert severity="warning">
                                                    <Text tid="ikkeveraktor" />
                                                </Alert>
                                            }
                                            <CardContent>
                                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography variant="h6" color="text.secondary" gutterBottom className='jvtypeicon'>
                                                        {(AktorId.toString() === jvsesaktorid) && <Tooltip title={Text({ tid: "ttaktivaktor" })}><DoneOutlineIcon color="success" sx={{ mr: 1 }} /></Tooltip>} {Navn} {(Verifisert === true) && <Tooltip title={Text({ tid: "ttaktorver" })}><VerifiedIcon className='jviconverified' sx={{ ml: 1 }} /></Tooltip>}
                                                    </Typography>
                                                </Grid>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3 }}>
                                                    <ListAltIcon className='jvtexticonnc' /> {OrgNummer}
                                                </Typography>
                                                <Box sx={{ mt: 2 }}>
                                                    {(AktorId.toString() !== jvsesaktorid) && <Button variant="outlined" size="small" color="primary" onClick={e => handleClickAktivAktor(AktorId, Navn, OrgNummer)} sx={{ mr: 1 }}><CheckCircleOutlineIcon className='jvbtnicon' /> <Text tid="settaktiv" /></Button>}
                                                    <Button variant="outlined" size="small" color="error" onClick={e => handleClickaktorSlettModalOpen(AktorId, Navn)}><DeleteForeverIcon className='jvbtnicon' /> <Text tid="sletttilknytning" /></Button>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </span>
                                })
                                }
                            </Grid>
                        </Grid>
                    </div>
            }
        </main>
    )
}

export default Profil;